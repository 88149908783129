const userRoleConstants = {
  ADMIN_ID: 1,
  DISTRIBUTOR_ID: 2,
  RETAILER_ID: 3,
  CUSTOMER_ID: 4,
  DISTRIBUTOR_USER_ID: 5,
};

const dateFilterConstants = {
  TODAY: 1,
  THIS_MONTH: 2,
  TILL_TODAY: 3,
  THIS_WEEK: 4,
};

const billingType = {
  CREDIT : 1,
  WALLET : 2 ,
};
  
const creditStatusType = {
  APPROVED: 1,
  DECLINED: 2,
  REQUESTED: 3,
  PAID: 4,
  UNPAID: 5,
};

const helpAndSupportTag = {
  OPEN: 1,
  PENDING: 2,
  SOLVED: 3,
  RE_OPEN: 4,
  CLOSED: 5,
};

module.exports = {
  userRoleConstants,
  dateFilterConstants,
  billingType,
  creditStatusType,
  helpAndSupportTag,
};
