import ApiConstants from "../utils/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const getAggregatorStatus = async () => {
  try {
    const response = await axios.get(`${baseUrl}/${ApiConstants.AGGREGATOR_STATUS}`);
    return response;
  } catch (error) {
    throw error;
  }
};
