import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
  amount : "",
};
 
const authSlice = createSlice({
  name: "amount",
  initialState,
  reducers: {
    setRechargeAmount: (state, action) => {
      state.amount = action.payload;
    },
    setRechargeCarrierId: (state, action) => {
      state.carrierId = action.payload;
    },

  },
 
});
 
export const { setRechargeAmount, setRechargeCarrierId } = authSlice.actions;
export default authSlice.reducer;