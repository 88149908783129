import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";
import { useDispatch } from "react-redux";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { postHelpAndSupportFilterApi } from "../../../redux/features/helpAndSupportSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";

const ManageFilter = ({ showFilter, setShowFilter, categoriesData, currentPage, currentSize, tagId }) => {
  const [userType, setUserType] = useState("");
  const [category, setCategory] = useState("");
  const [number, setNumber] = useState("");
  // const [status, setStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const RoleId = parseInt(localStorageStore.getRole());
  const dispatch = useDispatch();

  function handleClose() {
    setShowFilter(false);
  }

  const userTypeOption = [
    { label: "Retailer", value: "3" },
    { label: "Distributor", value: "2" },
    { label: "Customer", value: "4" },
  ];

  const categoryOption = () => {
    return categoriesData?.categories?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  // const tagsOption = () => {
  //   return categoriesData?.tags?.map((data) => ({
  //     label: data?.name,
  //     value: data?.id,
  //   }));
  // };

  const filterData = {
    id: number !== "" ? number : "",
    role_id: userType !== "" ? userType?.value : "",
    category_id: category !== "" ? category?.value : "",
    tag_id: tagId,
    createdAT: createdAt !== "" ? createdAt : "",
  };

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };
  const filteredData = filterEmptyFields(filterData);

  const submitFilterData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every((value) => {
      return value === "";
    });

    if (!isFilterEmpty) {
      const response = await dispatch(postHelpAndSupportFilterApi({ filteredData: filteredData, page: currentPage, size: currentSize }));

      if (response?.meta?.requestStatus === "fulfilled") {
        successToast("Filter applied successfully");
      } else {
        errorToast("Error occurred while filtering");
      }

      handleClose();
    } else {
      errorToast("No field selected for filter");
    }
  };

  const handleResetButton = () => {
    setNumber("");
    // setStatus("");
    setUserType("");
    setCategory("");
    setCreatedAt("");
    handleClose();
    dispatch(postHelpAndSupportFilterApi({ filteredData: { tag_id: tagId }, page: currentPage, size: currentSize }));
  };

  const handleEventReceiveDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setCreatedAt(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelReceiveDate = (event, picker) => {
    picker.element.val("");
    setCreatedAt("");
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light me-1" onClick={(e) => setShowFilter(true)}>
        <i className="ti ti-filter me-1"></i> Filter
      </button>
      {
        <Modal show={showFilter} size="sm" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <div className="modal-body ">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} />
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label className="form-label">Query Number</label>
                    <input type="text" className="form-control mb-3" placeholder="" value={number} onChange={(e) => setNumber(e.target.value)} />
                  </div>
                  {RoleId === userRoleConstants.ADMIN_ID && (
                    <div className="col-lg-12 mb-3">
                      <label className="form-label">User Type</label>
                      <Select options={userTypeOption} isMulti={false} onChange={setUserType} value={userType} />
                    </div>
                  )}
                  <div className="col-lg-12">
                    <label className="form-label">Date</label>
                    {/* <Flatpickr options={options_for_end_date} className="form-control mb-3" placeholder="DD-MM-YYYY HH:MM" name="date" value={createdAt} onChange={(dates) => setCreatedAt(dates[0])} />
                     */}
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
                        showDropdowns: true,
                      }}
                      onApply={handleEventReceiveDate}
                      onCancel={handleCancelReceiveDate}
                    >
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control mb-3" placeholder="Select Date" defaultValue={createdAt} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Category</label>
                    <Select options={categoryOption()} isMulti={false} onChange={setCategory} value={category} />
                  </div>
                  {/* <div className="col-lg-12">
                    <label className="form-label">Status</label>
                    <Select options={tagsOption()} isMulti={false} onChange={setStatus} value={status} />
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" onClick={submitFilterData}>
                      Submit
                    </button>
                    <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleResetButton}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default ManageFilter;
