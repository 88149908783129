import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getTopUpDetailApi, setFilteredChips, clearFilteredChips } from "../../../redux/features/topUpSlice";
import { getMasterData } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";

const FilterModal = ({ acc_no, currentSize, clearFilterValues, roleId, DISTRIBUTOR_ID }) => {
  const [showModalBox, setShowModalBox] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [submitDate, setSubmitDate] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [requesterName, setRequesterName] = useState("");
  const [carrier, setCarrier] = useState("");
  const [topUp, setTopUp] = useState("");
  const [status, setStatus] = useState("");
  const [masterData, setMasterData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    fetchMasterData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filterValue = () => {
      if (clearFilterValues === "transaction_id") setTransactionId("");
      if (clearFilterValues === "phone_code") setCountryCode("");
      if (clearFilterValues === "credited_at") setRequestDate("");
      if (clearFilterValues === "mobile_number") setMobileNumber("");
      if (clearFilterValues === "carrier_id") setCarrier("");
      if (clearFilterValues === "requested_by") setRequesterName("");
      if (clearFilterValues === "original_currency_amount") setTopUp("");
      if (clearFilterValues === "status_type_id") setStatus("");
    };

    filterValue();

    // eslint-disable-next-line
  }, [clearFilterValues]);

  const fetchMasterData = async (countryCode) => {
    try {
      const response = await getMasterData();
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  let page = 1;

  const handleTransactionId = (e) => {
    setTransactionId(e?.target?.value);
  };

  const handleCountryCode = (e) => {
    setCountryCode(e?.target?.value);
  };

  const handleMobileNumber = (e) => {
    setMobileNumber(e?.target?.value);
  };

  const handleRequesterName = (e) => {
    setRequesterName(e?.target?.value);
  };

  const handleTopUp = (e) => {
    setTopUp(parseFloat(e?.target?.value));
  };

  const handleEventRequestDate = (event, picker) => {
    picker.element.val(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setRequestDate(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setSubmitDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelRequestDate = (event, picker) => {
    picker.element.val("");
    setRequestDate("");
    setSubmitDate("");
  };

  const carrierOptions = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const statusOptions = [
    { label: "Successful", value: "1" },
    { label: "In Process", value: "2" },
    { label: "Failed", value: "3" },
  ];

  const filterData = {
    transaction_id: transactionId,
    phone_code: countryCode,
    credited_at: submitDate,
    mobile_number: mobileNumber,
    requested_by: requesterName,
    carrier_id: carrier?.value ?? "",
    original_currency_amount: topUp,
    status_type_id: status?.value ?? "",
    account_no: acc_no ?? "",
  };

  const filterChipsData = {
    transaction_id: transactionId,
    phone_code: countryCode,
    credited_at: submitDate,
    mobile_number: mobileNumber,
    requested_by: requesterName,
    carrier_id: carrier ?? "",
    original_currency_amount: topUp,
    status_type_id: status ?? "",
    account_no: acc_no ?? "",
  };

  if (roleId !== DISTRIBUTOR_ID) {
    delete filterData.account_no;
  }

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(filterData);
  const filteredChipsData = filterEmptyFields(filterChipsData);

  const submitFilterData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every(([key, value]) => {
      if (key === "account_no") {
        return true;
      }
      return value === "";
    });

    if (!isFilterEmpty) {
      const response = await dispatch(getTopUpDetailApi({ filteredData: filteredData, page, size: currentSize }));
      dispatch(setFilteredChips(Object.entries(filteredChipsData)));

      if (response?.meta?.requestStatus === "fulfilled") {
        successToast("Filter applied successfully");
      } else {
        errorToast("Error occurred while filtering");
      }

      setShowModalBox(!showModalBox);
    } else {
      errorToast("No field selected for filter");
    }
  };

  const handleCancel = () => {
    setTransactionId("");
    setCountryCode("");
    setRequestDate("");
    setMobileNumber("");
    setRequesterName("");
    setCarrier("");
    setTopUp("");
    dispatch(clearFilteredChips());
    setStatus("");
    setShowModalBox(!showModalBox);
    dispatch(getTopUpDetailApi(roleId === DISTRIBUTOR_ID ? { filteredData: { account_no: acc_no }, page, size: currentSize } : { filteredData: {}, page, size: currentSize }));
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light" onClick={() => setShowModalBox(true)}>
        <i className="ti ti-filter me-1" /> Filter
      </button>
      {
        <Modal show={showModalBox} size="lg" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <div className="modal-body ">
                <button type="button" className="btn-close" onClick={handleCancel} />
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-label">Transaction ID</label>
                    <input type="text" onChange={handleTransactionId} value={transactionId} className="form-control mb-3" />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Country Code</label>
                    <input type="text" onChange={handleCountryCode} value={countryCode} className="form-control mb-3" />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Mobile Number</label>
                    <input type="text" min={0} onChange={handleMobileNumber} value={mobileNumber} className="form-control mb-3" />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Requester's Name</label>
                    <input type="text" onChange={handleRequesterName} value={requesterName} className="form-control mb-3" />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Carrier</label>
                    <Select options={carrierOptions()} isMulti={false} onChange={(selectedOption) => setCarrier(selectedOption)} value={carrier} />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">
                      Top-Up <small>(USD)</small>
                    </label>
                    <input type="number" value={topUp} min={0} onChange={handleTopUp} className="form-control mb-3" />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Date</label>
                    <DateRangePicker initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: "Clear", format: "DD-MM-YYYY" }, showDropdowns: true, drops: "up" }} onApply={handleEventRequestDate} onCancel={handleCancelRequestDate}>
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={requestDate} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Status</label>
                    <Select options={statusOptions} isMulti={false} onChange={(selectedOption) => setStatus(selectedOption)} value={status} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" onClick={submitFilterData} className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter" data-bs-dismiss="modal" aria-label="Close">
                      Submit
                    </button>
                    <button type="reset" onClick={handleCancel} className="btn btn-label-secondary rounded-pill btn-reset waves-effect" data-bs-dismiss="modal" aria-label="Close">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default FilterModal;
