import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginTopUpBackground from "../Layout/AuthLayout/LoginTopUpBackground";
import { customerCurrencyConversion, rechargeRequest } from "../../services/Customer";
import { setRechargeAmount } from "../../redux/features/paymentGatewaySlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchCustomerProduct } from "../../services/customerService";
import { errorToast, successToast } from "../Toasts/Toasts";
import { notificationApi } from "../../redux/features/notificationSlice";
import PhoneInput from "react-phone-number-input";
import { Controller, useForm } from "react-hook-form";

const CustomerRechargeRequest = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [showProduct, setShowProduct] = useState(false);
  const [showTopUpAmount, setShowTopUpAmount] = useState(true);
  const [showArrow, setShowArrow] = useState(false);
  const [showTopUpInfo, setShowTopUpInfo] = useState(false);
  const [showText, setShowText] = useState(true);
  // eslint-disable-next-line
  const [phoneCode, setPhoneCode] = useState("");
  const [convertedCurrency, setConvertedCurrency] = useState({});
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [phoneNumber, setPhoneNumber] = useState("");
  const [productData, setProductData] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [size, setSize] = useState(10);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [rechargeRequestStatus, setRechargeRequestStatus] = useState("");
  const [name, setName] = useState("");
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState("");
  const [isValidInput, setIsValidInput] = useState(false);
  const [isNameValidInput, setIsNameValidInput] = useState(false);
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [rechargeBtndisabled, setRechargeBtnDisabled] = useState(true);
  const keywordRef = useRef("");
  const [parsedContactData, setParsedContactData] = useState('');
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchContactData = async () => {
      const ContactData = localStorage.getItem("profileData");
      const parsedContactData = JSON.parse(ContactData);
      if (parsedContactData === null || Object.keys(parsedContactData).length === 0) {
        setTimeout(fetchContactData, 1000); // Retry after 1 second if parsedContactData is still null or empty
      } else {
        setParsedContactData(parsedContactData);
      }
    };
    fetchContactData();

  }, []);

  const { control, setValue } = useForm({ defaultValues: { retailer_phone_number: '' } });
 
  useEffect(() => {
    setValue("retailer_phone_number",`${parsedContactData?.phone_code}${parsedContactData?.phone_number}`);

    // eslint-disable-next-line
  },[parsedContactData]);

  const handleChange = (value) => {};

  let page = 1;
  const dispatch = useDispatch();

  const handleProduct = () => {
    setShowProduct(true);
    setShowTopUpAmount(false);
    setShowArrow(true);
  };
  const showAmountDetails = () => {
    setShowProduct(false);
    setShowTopUpAmount(true);
    setShowArrow(false);
    setShowTopUpInfo(false);
    setShowText(true);
  };

  const handleRecharge = async () => {
    if (amount === "") {
      errorToast("Amount is required");
    } else if (amount < 40) {
      errorToast("Amount must be greater or equal to 40");
    } else if (amount > 5000) {
      errorToast("Amount must be less than or equal to 5000");
    } else {
      const userId = localStorage.getItem("userId");
      const obj = {
        converted_currency: convertedCurrency?.convertedCurrency,
        mobile_number: phoneNumber,
        original_currency_type: convertedCurrency?.sourceCurrency,
        phone_code: phoneCode,
        service_fee: convertedCurrency?.serviceFee,
        converted_amount: convertedCurrency?.convertedAmount,
        source_amount: convertedCurrency?.sourceAmount,
        total_converted_amount: convertedCurrency?.totalConvertedAmount,
        total_source_amount: convertedCurrency?.totalSourceAmount,
        vat: convertedCurrency?.vat,
        requested_by: userId,
      };
      const rechargeDetails = JSON.stringify(obj);
      localStorage.setItem("rechargeDetails", rechargeDetails);

      setShowTopUpInfo(true);
      setShowTopUpAmount(false);
      setShowArrow(true);
      setShowText(false);
    }
  };

  const handleContinueBtn = () => {
    if (selectedProduct === "" || selectedProduct === null) {
      setShowTopUpInfo(false);
      setShowProduct(true);
      setShowArrow(false);
      setShowText(true);
      errorToast("Select Product From the List");
    } else {
      const obj = {
        converted_currency: convertedCurrency?.convertedCurrency,
        mobile_number: phoneNumber,
        original_currency_type: convertedCurrency?.sourceCurrency,
        phone_code: phoneCode,
        service_fee: convertedCurrency?.serviceFee,
        converted_amount: convertedCurrency?.convertedAmount,
        source_amount: convertedCurrency?.sourceAmount,
        total_converted_amount: convertedCurrency?.totalConvertedAmount,
        total_source_amount: convertedCurrency?.totalSourceAmount,
        vat: convertedCurrency?.vat,
      };

      const rechargeDetails = JSON.stringify(obj);
      localStorage.setItem("rechargeDetails", rechargeDetails);
      setShowTopUpInfo(true);
      setShowProduct(false);
      setShowArrow(true);
      setShowText(false);
    }
  };

  useEffect(() => {
    if (!showText) {
      setSelectedOption(null);
    }

    // eslint-disable-next-line
  }, [showText]);

  let timeoutId;
  const handleAmount = async (event) => {
    setAmount(event?.target?.value);
    const keyword = event?.target?.value?.trim();
    setLoading(true);

    if (keyword !== "" && keyword.length >= 2) {
      setRechargeBtnDisabled(true);
      keywordRef.current = keyword; // Update the ref with the latest keyword
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        // Check if the current keyword is still the latest before making the API call
        if (keywordRef.current === keyword) {
          try {
            const response = await customerCurrencyConversion({ amount: parseFloat(keyword) });
            if (response) {
              setConvertedCurrency(response?.data);
              setLoading(false);
              setRechargeBtnDisabled(false);
            }
          } catch (error) {
            setLoading(false);
            errorToast(error?.response?.data?.message || error?.response?.data?.errors[0]?.message || 'Something went wrong');
            setConvertedCurrency(null);
            setRechargeBtnDisabled(true);
          }
        }
      }, 600);
    } else {
      setConvertedCurrency(null);
      setLoading(false);
      setRechargeBtnDisabled(true);
    }
  };

  const handleFinalAmount = () => {
    dispatch(setRechargeAmount((Math.round(convertedCurrency?.totalConvertedAmount * 100) / 100).toFixed(2)));
  };

  useEffect(() => {
    handleProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    try {
      const response = await fetchCustomerProduct(page, size + 10);

      if (response?.success) {
        const newData = response?.data?.records;
        if (newData?.length > 0 && newData?.length > currentResultSetLength) {
          setProductData(newData);
          setSize((prevSize) => prevSize + 10); // Increment page number
          setCurrentResultSetLength(newData?.length);
        } else {
          setHasMoreData(false);
        }
      } else {
        errorToast("Error occurred while loading more data");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while loading more data");
    } finally {
      setLoading(false);
    }
  }, [loading, hasMoreData, page, size, currentResultSetLength]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("productSelectionComponent");
      if (parseInt(scrollContainer.scrollHeight - scrollContainer.scrollTop) === parseInt(scrollContainer.clientHeight)) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("productSelectionComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  const handleProductData = async () => {
    try {
      const response = await fetchCustomerProduct(page, size);
      if (response?.success) {
        setProductData(response?.data?.records);
        setHasMoreData(response?.data?.records.length === size); // Set hasMore based on whether there's more data to load
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const basicSearch = (event) => {
    const keyword = event?.target?.value.toLowerCase().trim(); // Convert keyword to lowercase for case-insensitive search
    if (keyword === "") {
      // If the keyword is empty, reset productData to its original state
      handleProductData(); // Fetch the original product data
    } else {
      // If the keyword is not empty, filter the productData
      const filteredData = productData.filter((product) => product.source_currency_amount.toLowerCase().includes(keyword) || product.target_currency_amount.toLowerCase().includes(keyword));
      setProductData(filteredData);
    }
  };

  const handleRadioSelect = async (index) => {
    try {
      setLoading(true);
      let AfnValue = productData[index];
      setAmount(AfnValue);
      let response = await customerCurrencyConversion({ amount: AfnValue !== "" ? AfnValue?.source_currency_amount : 0 });
      if (response) {
        setConvertedCurrency(response?.data);
        setLoading(false);
      }
      setDisabledContinue(false);
      setSelectedOption(index);
      setSelectedProduct(AfnValue);
    } catch (error) {
      setLoading(false);
      errorToast(error?.response?.data?.message || error?.response?.data?.errors[0]?.message || 'Something went wrong');
      setDisabledContinue(true);
      setSelectedOption(index);
    }
  };

  useEffect(() => {
    const rechargeRequest = localStorage.getItem("rechargeRequestStatus");

    if (rechargeRequest) {
      setRechargeRequestStatus(rechargeRequest);
    }
  }, []);

  const handleName = (e) => {
    if (e?.target?.value.trim().length >= 2) {
      setIsNameValidInput(true);
    } else {
      setIsNameValidInput(false);
    }
    setName(e?.target?.value);
  };

  const handleEmail = (e) => {
    const value = e?.target?.value.trim();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const phoneNumberPattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    setEmailOrPhoneNumber(value);

    if (emailPattern.test(value)) {
      setIsValidInput(true);
    } else if (phoneNumberPattern.test(value)) {
      setIsValidInput(true);
    } else {
      setIsValidInput(false);
    }
  };

  const obj = {
    receiver_name: name,
    receiver_email_or_number: emailOrPhoneNumber,
    amount: convertedCurrency?.totalSourceAmount,
    source_currency: convertedCurrency?.sourceCurrency,
    converted_currency: convertedCurrency?.convertedCurrency,
    source_amount: convertedCurrency?.sourceAmount,
    total_source_amount: convertedCurrency?.totalSourceAmount,
    converted_amount: convertedCurrency?.convertedAmount,
    total_converted_amount: convertedCurrency?.totalConvertedAmount,
    vat: convertedCurrency?.vat,
    carrier_id: parsedContactData?.carrier_id,
    service_fee: convertedCurrency?.serviceFee,
  };

  const handleSendRequest = async () => {
    if (!isValidInput || !isNameValidInput) {
      if (!isNameValidInput || emailOrPhoneNumber?.length === 0) {
        errorToast("Details are required");
      } else {
        errorToast("Invalid email or phone number format");
      }
    } else {
      const button = document.getElementById("send-request-btn");
      try {
        button.disabled = true;
        const response = await rechargeRequest(obj);
        button.disabled = false;
        if (response) {
          successToast("Recharge request sent successfully");
          localStorage.setItem("rechargeRequestStatus", 0);
          dispatch(notificationApi());
          navigate("/customer/profile");
        }
      } catch (error) {
        localStorage.setItem("rechargeRequestStatus", 0);
        navigate("/customer/profile");
        button.disabled = false;
      }
    }
  };


  return (
    <section id="hero-animation">
      <div id="landingHero" className="section-py landing-hero position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="top-up">
                <div className="authentication-wrapper authentication-basic container-p-y pt-0 mt-3">
                  <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2 pt-0">
                    {/* Login */}
                    <div className="card card-topup bg-outline-primary mt-0 sending-topup scrollbar">
                      <div className="card-header d-flex justify-content-center pb-2 text-center">
                        <div className="back-button-products back">
                          {showArrow && (
                            <Link to="" type="button" onClick={showAmountDetails}>
                              <i className="ti ti-arrow-narrow-left ti-sm back-button" />
                            </Link>
                          )}
                        </div>
                        {showText && (
                          <>
                            <div className="card-title mb-3">
                              <h4 className="m-0 me-2">You are sending Top-Up to</h4>
                            </div>
                            <div></div>
                          </>
                        )}
                        {showTopUpInfo && (
                          <div className="card-title mb-3">
                            <h4 className="m-0 me-2">Your Top-Up Info</h4>
                          </div>
                        )}
                      </div>
                      {parsedContactData &&<div className="row pb-3 border-bottom border-bottom-dashed">
                        <div className="row ps-5">
                          <div className="col-md-6 d-flex mb-3 justify-content-between">
                            <div className="d-flex">
                              <Controller
                                name="retailer_phone_number"
                                control={control}
                                rules={{
                                  required: "Required",
                                }}
                                render={({ field }) => (
                                  <>
                                    <PhoneInput
                                      className="form-control-phone-input"
                                      international
                                      countryCallingCodeEditable={false}
                                      // countrySelectProps={{ unicodeFlags: true }}
                                      value={field.value}
                                      onChange={(value) => {
                                      handleChange(value);
                                      }}
                                    />
                                  </>
                                )}
                              />
                              <h6 className="mb-0 me-1" style={{"alignContent": "center"}}>{`${parsedContactData?.phone_code}${parsedContactData?.phone_number}`}</h6>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex">
                            <img src={parsedContactData?.carrier_name === "Roshan" ? "/assets/img/roshan.png" : parsedContactData?.carrier_name === "Etisalat" ? "/assets/img/etisalat-lg.png" : "/assets/img/roshan.png"} alt="Roshan" className="me-3" width="50" height="30" />
                            <div className="align-items-left">
                              <h5 className="mb-0 me-1">
                                {parsedContactData?.carrier_name} <small> (carrier)</small>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>}
                      {showTopUpAmount && (
                        <div className="card-body p-1 px-0 enter-product-amount">
                          <div className="d-flex justify-content-end pt-3">
                            <Link to="" className="select-products" onClick={handleProduct}>
                              Select From Products
                            </Link>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-xl-8 col-md-7 col-7 mb-0 mt-2">
                              <div className="mt-0 mb-4">
                                <div>
                                  <label htmlFor="" className="form-label text-left d-flex justify-content-start">
                                    Enter Amount
                                  </label>
                                  <div className="input-group input-group-merge rounded-pill  text-center">
                                    <input type="number" className="form-control" onChange={(e) => {handleAmount(e)}} placeholder={500} min={0} value={amount} />
                                    <span className="input-group-text">AFN</span>
                                  </div>
                                  {!loading && <small className="text-primary text-center">{amount?.length === 0 ? "0 USD" : `${convertedCurrency?.totalConvertedAmount ?? ''} ${convertedCurrency?.convertedCurrency ?? ''}`} </small>}
                                  {loading && (
                                    <div className="text-center">
                                      <span className=" mt-2 spinner-border h-15px w-15px align-middle text-danger" />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <button className="btn-md btn btn-primary rounded-pill text-white zindex-5 recharge-product mb-3" onClick={handleRecharge} disabled={rechargeBtndisabled}>
                                Recharge
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {showProduct && (
                        <div className="card-body p-1 px-0">
                          <div className="mb-3 row mt-3">
                            <div className="col-lg-12">
                              <input type="text" placeholder="Search for products" className="form-control mb-0" onChange={basicSearch} />
                            </div>
                          </div>
                          <InfiniteScroll dataLength={productData.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : ""} scrollableTarget="productSelectionComponent">
                            <div className="products-list" id="productSelectionComponent" style={{ height: "300px", overflowY: "auto" }}>
                              <div className="demo-inline-spacing mt-0">
                                <div className="list-group mt-0 me-1" style={{ marginTop: "0 !important" }}>
                                  {productData &&
                                    productData?.map((productDetails, index) => (
                                      <div className={`form-check custom-option custom-option-basic w-100 ${selectedOption === index ? "checked" : ""}`} key={index}>
                                        <label className="d-flex justify-content-between form-check-label custom-option-content" htmlFor={`customRadioTemp${index}`}>
                                          <div>
                                            <p className="mb-0 text-primary h5 d-table">
                                              {parseFloat(productDetails.source_currency_amount).toFixed(4)}
                                              <small> AFN</small> <small className="text-muted ms-2">(Approx.)</small>
                                            </p>
                                            <p className="mb-1 h6 d-table">
                                              {parseFloat(productDetails.target_currency_amount).toFixed(4)}
                                              <small> USD</small> <small className="text-muted ms-2">(Approx.)</small>
                                            </p>
                                          </div>
                                          <div className="mt-2 pt-1">
                                            <input name="select-plan" className="form-check-input" type="radio" value={index} id={`customRadioTemp${index}`} onChange={() => handleRadioSelect(index)} />
                                          </div>
                                        </label>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </InfiniteScroll>
                          {loading && (
                            <div className="text-center">
                              <span className=" mt-2 spinner-border h-15px w-15px align-middle text-danger" />
                            </div>
                          )}
                          <div className="row justify-content-center mt-3 border-top">
                            <div className="col-xl-5 col-md-4 col-6 mb-0 mt-3 mb-3">
                              <button className="btn-md btn btn-primary rounded-pill text-white zindex-5 select-order" onClick={handleContinueBtn} disabled={disabledContinue}>
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {showTopUpInfo && (
                        <div className="row justify-content-center px-4">
                          <div className="col-md-12">
                            <div className="d-flex w-100 flex-wrap justify-content-between py-2 mt-3">
                              <div className="me-2">
                                <h5 className="mb-0">Amount</h5>
                                {/* <small class="text-muted d-block">Item: #FXZ-4567</small> */}
                              </div>
                              <div>
                                <div className="user-progress d-flex align-items-center gap-1">
                                  <p className="mb-0 fw-medium h5">
                                    <span className="text-primary me-2">
                                      ({convertedCurrency?.sourceAmount}
                                      <small>{convertedCurrency?.sourceCurrency}</small>)
                                    </span>
                                    <span className="h5">
                                      {convertedCurrency?.convertedAmount} <small>{convertedCurrency?.convertedCurrency}</small>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                              <div className="me-2">
                                <h5 className="mb-0">Service Fee</h5>
                              </div>
                              <div className="user-progress d-flex align-items-center gap-1">
                                <p className="mb-0 fw-medium h5">
                                  {convertedCurrency?.serviceFee} <small>USD</small>
                                </p>
                              </div>
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                              <div className="me-2">
                                <h5 className="mb-0">Taxes</h5>
                              </div>
                              <div className="user-progress d-flex align-items-center gap-1">
                                <p className="mb-0 fw-medium h5">
                                  {convertedCurrency?.vat} <small>USD</small>
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between border-top mt-2 pt-2">
                              <h4>Total</h4>
                              <div>
                                <p className=" h3">
                                  <span className="text-primary me-2 fs-5">
                                    ({convertedCurrency?.totalSourceAmount} <small>{convertedCurrency?.sourceCurrency}</small>)
                                  </span>
                                  {convertedCurrency?.totalConvertedAmount}
                                  <small>{convertedCurrency?.convertedCurrency}</small>
                                </p>
                              </div>
                            </div>
                          </div>
                          {rechargeRequestStatus !== "1" ? (
                            <div className="col-md-12">
                              <Link to={token !== null ? "" : "/customer/login"} onClick={handleFinalAmount} className="btn btn-md btn-primary rounded-pill mt-2 mb-4">
                                Checkout
                              </Link>
                            </div>
                          ) : (
                            <div className="confirm-data px-2" style={{ display: "block" }}>
                              <hr />
                              <h5 className="mb-3 d-flex justify-content-start">Request it from</h5>
                              <div className="row justify-content-center">
                                <div className="col-xl-11 col-md-4 col-6 mb-0 mt-0">
                                  <div className="row mt-0 mb-4 text-center">
                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <label htmlFor="name" className="form-label d-flex justify-content-start">
                                          Name
                                        </label>
                                        <div className="form-group">
                                          <input type="text" name="name" className="form-control rounded-pill" onChange={handleName} defaultValue="" placeholder="" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <label htmlFor="email" className="form-label d-flex justify-content-start">
                                          Email / Mobile
                                        </label>
                                        <div className="form-group">
                                          <input type="email" name="email" id="email" className="form-control rounded-pill" onChange={handleEmail} defaultValue="" placeholder="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button type="button" onClick={handleSendRequest} className="btn btn-md btn-primary rounded-pill mt-2 mb-4" id="send-request-btn">
                                    Send Request
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginTopUpBackground />
      </div>
    </section>
  );
};

export default CustomerRechargeRequest;
