import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddContact from "./AddContact";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorToast } from "../../Toasts/Toasts";
import { getCustomerContactList } from "../../../services/customerService";

const ContactsList = ({ setIndexSelected, showCustomeAlertModal, setShowCustomeAlertModal, customerContactList, setSelectedContact, masterData, handleReRender, selectedContact, size, setSize, setCustomerContactList, setAlertMessage, setShowClassName, setIconClassName }) => {
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  let page = 1;

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    try {
      let response = await getCustomerContactList(page, size + 10);

      if (response?.success) {
        const newData = response?.data;
        if (newData?.length > 0 && newData?.length > currentResultSetLength) {
          setCustomerContactList(response?.data);
          setSize((prevSize) => prevSize + 10); // Increment page number
          setCurrentResultSetLength(newData?.length);
        } else {
          setHasMoreData(false);
        }
      } else {
        errorToast("Error occurred while loading more data");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while loading more data");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [loading, hasMoreData, page, size, currentResultSetLength]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("contactListComponent");
      if (parseInt(scrollContainer.scrollHeight - scrollContainer.scrollTop) === parseInt(scrollContainer.clientHeight)) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("contactListComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  const fetchContactId = (contactList, index)=>{
    setSelectedContact(contactList);
    setIndexSelected(index);
    localStorage.setItem('selectedContactIndex',index);
  }

  useEffect(() => {
    const fetchContactData = async () => {
    const selectedContactIndex = localStorage.getItem('selectedContactIndex');
   
      if (!selectedContactIndex) {
        setTimeout(fetchContactData, 1000); // Retry after 1 second if parsedContactData is still null or empty
      } else {
        setIndexSelected(parseInt(selectedContactIndex));
      }
    };
    fetchContactData();
    // eslint-disable-next-line
  },[setIndexSelected]);
  
  return (
    <>
      <div className="d-flex justify-content-between flex-column mb-2 mb-md-0 number-list">
        <div className="card-header d-flex justify-content-between py-3 pe-0 border-bottom">
          <h4 className="mb-0 pt-1 card-title">My Contacts</h4>
          <Link to="#" className="btn btn-secondary rounded-pill btn-sm add-mobile-btn" onClick={() => setShowAddContactModal(true)}>
            <i className="ti ti-plus" /> Add
          </Link>
        </div>
        <InfiniteScroll dataLength={customerContactList?.length || 0} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : ""} scrollableTarget="contactListComponent">
          <div className="card-body pe-0" id="contactListComponent" style={{ height: "600px", overflowY: "auto", paddingTop: "6%" }}>
            <ul className="nav nav-align-left nav-pills flex-column">
              {customerContactList?.map((contactList, index) => (
                <li className="nav-item my-1" key={contactList?.id}>
                  <button className={`nav-link ${selectedContact?.id === contactList?.id ? "active" : ""}`} data-bs-toggle="tab" data-bs-target="#own_recharge"onClick={() => fetchContactId(contactList,index)}>
                    <div className="align-items-start fw-medium d-flex justify-content-between w-100">
                      <div className="chat-contact-info flex-grow-1 ">
                        <h6 className="chat-contact-name text-truncate m-0 text-dark">{contactList?.contact_record_type === "customer" ? "ME" : contactList?.name}</h6>
                        <p className="mb-0">{contactList?.phone_code && contactList?.phone_number && `${contactList.phone_code} ${contactList?.phone_number}`}</p>
                      </div>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </InfiniteScroll>
      </div>
      {showAddContactModal && <AddContact showAddContactModal={showAddContactModal} setShowAddContactModal={setShowAddContactModal} showCustomeAlertModal={showCustomeAlertModal} setShowCustomeAlertModal={setShowCustomeAlertModal} masterData={masterData} handleReRender={handleReRender} setAlertMessage={setAlertMessage} setShowClassName={setShowClassName} setIconClassName={setIconClassName} />}
    </>
  );
};

export default ContactsList;
