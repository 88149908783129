import React, { useEffect, useState } from "react";
import BillingHeader from "./BillingHeader";
import BillingGrid from "./BillingGrid";
import { getTransactionDetailApi, setFilteredChips } from "../../../redux/features/transactionCreditSlice";
import { useDispatch, useSelector } from "react-redux";
import localStorageStore from "../../../utils/localStorageStore";
import { userRoleConstants, creditStatusType } from "../../../utils/constants";

const ManageBilling = () => {
  let billingId = localStorage.getItem('billing_type_id');
  const [hasMoreData, setHasMoreData] = useState(true);
  const [distributorName, setDistributorName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [retailerName, setRetailerName] = useState("");
  const [credit_status_type_id, setStatus] = useState("");
  const [submitPaymentDate, setSubmitPaymentDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [submitDueDate, setSubmitDueDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [submitCreditDate, setSubmitCreditDate] = useState("");
  const [creditDate, setCreditDate] = useState("");
  const [submitRequestDate, setSubmitRequestDate] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [currentSize, setCurrentSize] = useState(10);
  const [creditedToOrBy, setCreditedToOrBy] = useState("credited_to");
  const roleId = parseInt(localStorageStore.getRole());
  const [billingTypeId, setBillingTypeId] = useState();
  const [selectedRoleTabId, setSelectedRoleTabId] = useState(billingId === "1" && roleId === 3 ? 0 : billingId === "2" && roleId === 3 ? 1 : 0);
  const [paymentTypeTabId, setPaymentTypeTabId] = useState(billingId === "1" && (roleId === 2  || roleId === 5 || roleId === 3)&& selectedRoleTabId === 0 ? 1 : billingId === "2" && (roleId === 2 || roleId === 5 || roleId === 3) && selectedRoleTabId === 0 ? 1 : 2);

  useEffect(()=>{

    if(billingId) {
      setBillingTypeId(billingId)
    }

  },[billingId, selectedRoleTabId, roleId])

  const filteredChips = useSelector((state) => state?.transaction?.filteredChips);
  const dispatch = useDispatch();

  const page = 1;

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips.filter(([existingKey]) => existingKey !== key);
    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredChips(updatedChips));

    const convertedChips = Object.fromEntries(updatedChips);

    let transformedChips = {};

    for (let key in convertedChips) {
      if (typeof convertedChips[key] === "object" && "label" in convertedChips[key] && "value" in convertedChips[key]) {
        transformedChips[key] = convertedChips[key].value;
      } else {
        transformedChips[key] = convertedChips[key];
      }
    }

    transformedChips.billing_type_id = roleId === userRoleConstants.ADMIN_ID ? paymentTypeTabId : roleId === userRoleConstants.RETAILER_ID ? (selectedRoleTabId === 0 ? 1 : 2) : paymentTypeTabId;
    transformedChips.type = roleId === userRoleConstants.ADMIN_ID ? "credited_by" : roleId === userRoleConstants.RETAILER_ID ? "credited_to" : creditedToOrBy;
    // Call the clearFilterValues function to clear the corresponding state values

    if (key === "transaction_id") {
      setTransactionId("");
    } else if (key === "amount") {
      setAmount("");
    } else if (key === "request_date") {
      setRequestDate("");
      setSubmitRequestDate("");
    } else if (key === "credit_date") {
      setCreditDate("");
      setSubmitCreditDate("");
    } else if (key === "due_date") {
      setDueDate("");
      setSubmitDueDate("");
    } else if (key === "payment_date") {
      setPaymentDate("");
      setSubmitPaymentDate("");
    } else if (key === "credit_status_type_id") {
      setStatus("");
    } else if (key === "account_no") {
      setAccountNumber("");
    } else if (key === "name") {
      setRetailerName("");
      setDistributorName("");
    } else {
    }

    // Call the product filter API with the updated filteredChips
    dispatch(getTransactionDetailApi({ filterData: transformedChips, page, size: currentSize + 10 }));
    // eslint-disable-next-line
  };

  useEffect(() => {
    setTransactionId("");
    setAmount("");
    setRequestDate("");
    setSubmitRequestDate("");
    setCreditDate("");
    setSubmitCreditDate("");
    setDueDate("");
    setSubmitDueDate("");
    setPaymentDate("");
    setSubmitPaymentDate("");
    setStatus("");
    setRetailerName("");
    setAccountNumber("");
    setDistributorName("");
  }, [paymentTypeTabId, selectedRoleTabId]);

  return (
    <>
      <BillingHeader setSelectedRoleTabId={setSelectedRoleTabId} setPaymentTypeTabId={setPaymentTypeTabId} selectedRoleTabId={selectedRoleTabId} paymentTypeTabId={paymentTypeTabId} roleId={roleId} userRoleConstants={userRoleConstants} setCreditedToOrBy={setCreditedToOrBy} creditedToOrBy={creditedToOrBy} currentSize={currentSize} transactionId={transactionId} setTransactionId={setTransactionId} setAmount={setAmount} amount={amount} requestDate={requestDate} setRequestDate={setRequestDate} submitRequestDate={submitRequestDate} setSubmitRequestDate={setSubmitRequestDate} creditDate={creditDate} setCreditDate={setCreditDate} submitCreditDate={submitCreditDate} setSubmitCreditDate={setSubmitCreditDate} dueDate={dueDate} setDueDate={setDueDate} submitDueDate={submitDueDate} setSubmitDueDate={setSubmitDueDate} paymentDate={paymentDate} setPaymentDate={setPaymentDate} submitPaymentDate={submitPaymentDate} setSubmitPaymentDate={setSubmitPaymentDate} credit_status_type_id={credit_status_type_id} setStatus={setStatus} retailerName={retailerName} setRetailerName={setRetailerName} accountNumber={accountNumber} setAccountNumber={setAccountNumber} distributorName={distributorName} setDistributorName={setDistributorName} billingTypeId={billingTypeId} setCurrentSize={setCurrentSize} setHasMoreData={setHasMoreData}/>
      <BillingGrid selectedRoleTabId={selectedRoleTabId} paymentTypeTabId={paymentTypeTabId} roleId={roleId} userRoleConstants={userRoleConstants} creditedToOrBy={creditedToOrBy} creditStatusType={creditStatusType} setCurrentSize={setCurrentSize} currentSize={currentSize} filteredChips={filteredChips} handleChipRemove={handleChipRemove} hasMoreData={hasMoreData} setHasMoreData={setHasMoreData}/>    
    </>
  );
};

export default ManageBilling;
