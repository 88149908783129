import React, { useEffect, useState } from "react";
import Headers from "./Headers";
import OrdersTable from "./OrdersTable";
import { useDispatch, useSelector } from "react-redux";
import { getTopUpDetailApi } from "../../../redux/features/topUpSlice";
import { useParams } from "react-router-dom";
import { userRoleConstants } from "../../../utils/constants";
import decrypt from "../../../utils/decrypt";

const RetailerTransaction = () => {
  const {acc_no} = useParams();
  const [currentSize ,setCurrentSize] = useState(10);
  const [clearFilterValues, setClearFilterValues] = useState("");
  const encryptedRoleId = localStorage.getItem("roleId")
  const roleId = parseInt(decrypt(encryptedRoleId))
  const { DISTRIBUTOR_ID, DISTRIBUTOR_USER_ID } = userRoleConstants
  let page = 1;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopUpDetailApi((roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID) ? { filteredData: {account_no: acc_no}, page, size : currentSize } : { filteredData: {}, page, size : currentSize })); 
    // eslint-disable-next-line
  }, [dispatch, acc_no]);

  const topUpTransaction = useSelector((state) => state?.topUpSlice?.topUpDetails);
  const headerDetails = useSelector((state) => state?.topUpSlice?.count);
  return (
    <>
      <Headers headerDetails={headerDetails} acc_no={acc_no} currentSize={currentSize} clearFilterValues={clearFilterValues} />
      <OrdersTable topUpTransaction={topUpTransaction} acc_no={acc_no} setCurrentSize={setCurrentSize} currentSize={currentSize} setClearFilterValues={setClearFilterValues} roleId={roleId} DISTRIBUTOR_ID={DISTRIBUTOR_ID} />
    </>
  );
};

export default RetailerTransaction;
