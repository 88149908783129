import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userLogin, userSetPassword } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";
import encrypt from "../../../utils/encrypt";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../redux/features/authSlice";

function SetPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const watchPassword = watch("newPassword", "");

  const registerOptions = {
    newPassword: {
      required: "Required",
      pattern: {
        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
        message: "Password must be between 8 and 20 characters and contain at least one letter, one digit, and one special character.",
      },
    },
    confirm_password: {
      required: "Required",
      validate: (value) => value === watchPassword || "Passwords do not match",
    },
  };

  const onSubmit = async (data) => {
    try {
      const button = document.getElementById("btnSave");
      button.disabled = true;

      let obj = {
        userEmailOrPhone: location?.state?.userEmailOrPhone || "",
        resetToken: location?.state?.resetToken || "",
        newPassword: data?.newPassword,
        loginType: location?.state?.loginType || "",
      };

      const response = await userSetPassword(obj);
      button.disabled = false;

      if (response?.success) {
        successToast(response?.message);
        let setPasswordPath = "";
        if (location.pathname.startsWith("/customer/account-security")) {
          setPasswordPath = "/customer/login";
        } else if (location.pathname.startsWith("/customer/register/account-security")) {
          // setPasswordPath = "/customer/login";
          let obj = {
            password: encrypt(data?.newPassword),
            userEmailOrPhone: location?.state?.userEmailOrPhone || "",
            loginType: location?.state?.loginType || "",
          };

          try {
            let response = await userLogin(obj);

            if (response?.success) {
              localStorage.setItem("token", response?.token);
              localStorage.setItem("roleId", response?.role_id);

              dispatch(
                setLogin({
                  isAuthenticated: true,
                  roleId: response?.role_id,
                })
              );
              setPasswordPath = "/customer/profile";
            } else {
              setPasswordPath = "/customer/login";
            }
            navigate(setPasswordPath);
          } catch (error) {
            errorToast(error?.response?.data?.message ?? "Something went wrong");
          }
        } else {
          setPasswordPath = "/";
        }
        navigate(setPasswordPath);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
              <div className="card card-topup bg-outline-primary mt-4">
                <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                  <div className="w-px-400 mx-auto">
                    <div className="card-body">
                      <h3 className="mb-5 text-center">Set Password</h3>
                      <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group input-group-merge rounded-pill">
                            <input id="newPassword" className="form-control" type="password" name="newPassword" placeholder="........." minLength={8} maxLength={20} {...register("newPassword", registerOptions.newPassword)} />
                          </div>
                          <small className="text-danger">{errors?.newPassword && errors.newPassword.message}</small>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-group input-group-merge rounded-pill">
                            <input className="form-control" type="password" name="confirm_password" placeholder=".........." minLength={8} maxLength={20} {...register("confirm_password", registerOptions.confirm_password)} />
                          </div>
                          <small className="text-danger">{errors?.confirm_password && errors.confirm_password.message}</small>
                        </div>
                        <button type="submit" className="btn btn-primary w-100 text-white d-grid rounded-pill signup" id="btnSave">
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetPasswordForm;
