import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { errorToast, successToast } from "../../Toasts/Toasts";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { addContact } from "../../../services/customerService";

const AddContact = ({ showAddContactModal, setShowAddContactModal, showCustomeAlertModal, setShowCustomeAlertModal, masterData, handleReRender, setAlertMessage, setShowClassName, setIconClassName }) => {
  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"];
  const maxSize = 1 * 1024 * 1024; // 1 MB;

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const handleClose = () => {
    setShowAddContactModal(false);
    setShowCustomeAlertModal(true);
  };

  useEffect(() => {
    // Check if phoneNumber starts with "78" or "73" to select Etisalat
    if (phoneNumber.startsWith('78') || phoneNumber.startsWith('73')) {
      const etisalatCarrier = masterData?.carriers?.find(carrier => carrier?.name === 'Etisalat');
      if (etisalatCarrier) {
        setValue('carrier', { label: etisalatCarrier?.name, value: etisalatCarrier?.id });
      }
    } else if (phoneNumber.startsWith('79') || phoneNumber.startsWith('72')) {
      const roshanCarrier = masterData?.carriers?.find(carrier => carrier?.name === 'Roshan');
      if (roshanCarrier) {
        setValue('carrier', { label: roshanCarrier?.name, value: roshanCarrier?.id });
      }
    }
    else{
      setValue('carrier', '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check if the file type is allowed
      if (ALLOWED_FILE_TYPES.includes(file.type)) {
        if (file.size <= maxSize) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            setAvatar(base64String); // Store the base64 string
          };
          reader.readAsDataURL(file);
        } else {
          // Show error message for oversized file
          errorToast("Maximum file size allowed is 1 MB.");
        }
      } else {
        // Show error message for invalid file type
        errorToast("Only JPEG, PNG, and JPG images are allowed.");
      }
    }
  };

  const carrierOptions = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const onSubmit = async (data) => {
    const button = document.getElementById("saveButton");
    let ContactInfo = {
      name: data?.customer_name,
      phone_code: phoneCode,
      phone_number: phoneNumber,
      carrier_id: data?.carrier.value,
      email: data?.email,
      image: avatar,
    };

    try {
      if (ContactInfo.phone_number !== "") {
        button.disabled = true;
        const response = await addContact(ContactInfo);
        button.disabled = false;

        if (response?.success) {
          button.disabled = false;
          successToast(response?.message);
          handleReRender(response);
          handleClose();
          setAlertMessage('Contact has been added!');
          setShowClassName('text-success check info');
          setIconClassName('ti ti-check fs-1');

        } else {
          errorToast(response?.message);
        }
      } else {
        setPhoneCodeError(true);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while adding the product.");
      button.disabled = false;
    }
  };

  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
    if (parsedPhoneNumber) {
      setPhoneCode(`+${parsedPhoneNumber?.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber?.nationalNumber);
      setValue("phone_number", stringValue);
      setPhoneCodeError(false);
    } else {
      setPhoneCode("");
      setPhoneNumber("");
      // setValue("phone_number", "");
    }
    if (value) {
      setValue("phone_number", value, { shouldValidate: true });
      setPhoneCodeError(false);
    }
    if (value === undefined) {
      setValue("phone_number", setPhoneCode, { shouldValidate: true });
      setPhoneCodeError(true);
    }
  };

  return (
    <>
      <Modal show={showAddContactModal} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowAddContactModal(false)} />
              <div className="text-left mb-4">
                <h3 className="mb-2">Add Contact</h3>
              </div>
              <div className="add-number">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="profile-change gap-4">
                        <div
                          className="custom-img-container rounded"
                          style={{
                            backgroundImage: `url(${avatar})`,
                          }}
                        />
                        <div className="button-wrapper mt-3">
                          <label htmlFor="upload" className="btn btn-outline-primary rounded-pill btn-sm me-2 mb-3 waves-effect waves-light" tabIndex={0}>
                            <span className="d-none d-sm-block">Upload new photo</span>
                            <i className="ti ti-upload d-block d-sm-none" />
                            <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg, image/jpg" onChange={handleImageChange} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div>
                        <label htmlFor="" className="form-label">
                          Name
                        </label>
                        <input type="text" placeholder="" className="form-control" name="customer_name" minLength={1} maxLength={256} {...register("customer_name", { required: "required" })} />
                        <small className="text-danger">{errors?.customer_name && errors.customer_name.message}</small>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <div>
                        <label htmlFor="" className="form-label">
                          Mobile Number
                        </label>
                        <Controller
                          name="phone_number"
                          control={control}
                          rules={{
                            required: "required",
                          }}
                          render={({ field }) => (
                            <>
                              <PhoneInput
                                className="form-control"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AF"
                                value={field.value}
                                onChange={(value) => {
                                  handleChange(value);
                                }}
                              />
                              {phoneCodeError && <small className="text-danger">required</small>}
                              {errors?.phone_number && <small className="text-danger">{errors.phone_number.message}</small>}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <div>
                        <label htmlFor="" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className={`form-control  ${errors.email ? "is-invalid" : ""}`}
                          id="email"
                          {...register("email", {
                            required: "required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          placeholder="Enter your email"
                        />
                        {errors.email && <small className="text-danger">{errors.email.message}</small>}{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <label className="form-label">Carrier</label>
                      <Controller
                        name="carrier"
                        control={control}
                        rules={{ required: "required" }}
                        render={({ field }) => (
                          <>
                            <Select {...field} options={carrierOptions()} isSearchable placeholder="Select Carrier..." />
                            {errors.carrier && <small className="text-danger">{errors.carrier.message}</small>}
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 text-center mt-4">
                      <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-xl-1" id="saveButton">
                        Save
                      </button>
                      <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={() => setShowAddContactModal(false)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddContact;
