import React, { useState } from "react";
import BillingFilterModal from "./BillingFilterModal";
import { getReport } from "../../../services/transactionService";
import { errorToast } from "../../Toasts/Toasts";

const BillingHeader = ({ setSelectedRoleTabId, setPaymentTypeTabId, selectedRoleTabId, paymentTypeTabId, roleId, userRoleConstants, setCreditedToOrBy, creditedToOrBy, currentSize, transactionId, setTransactionId, setAmount, amount, requestDate, setRequestDate, submitRequestDate, setSubmitRequestDate, creditDate, setCreditDate, submitCreditDate, setSubmitCreditDate, dueDate, setDueDate, submitDueDate, setSubmitDueDate, paymentDate, setPaymentDate, submitPaymentDate, setSubmitPaymentDate, credit_status_type_id, setStatus, retailerName, setRetailerName, accountNumber, setAccountNumber, distributorName, setDistributorName, billingTypeId, setCurrentSize, setHasMoreData }) => {
  const [billingFilterShow, setBillingFilterShow] = useState(false);

  const handleReportDownload = async () => {
    try {
      const response = await getReport({ type: creditedToOrBy, page: 1, size: currentSize, billing_type: paymentTypeTabId });
      const blob = new Blob([response], { type: "application/vnd.ms-excel; charset=utf-8" });
      const url = URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = url;
      alink.download = "";
      alink.click();
    } catch(error) {
      errorToast('Something went wrong');
    };
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-1 pt-1"> Billing</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="nav-align-top">
            <ul className="nav nav-pills mb-3 border-bottom pb-3" role="tablist">
            {(roleId === 3 && billingTypeId === "1") ? <li className="nav-item" role="presentation">
                <button type="button" className="nav-link active" role="tab" onClick={() => { setCreditedToOrBy("credited_to"); setHasMoreData(true); setCurrentSize(10); setSelectedRoleTabId(0); }} data-bs-toggle="tab" data-bs-target="#navs-pills-top-shaad" >
                  <i className={roleId !== userRoleConstants.RETAILER_ID ? "ti ti-user me-1" : "ti ti-credit-card me-1"} /> {roleId !== userRoleConstants.RETAILER_ID ? "Shaad" : "Credit"}
                </button>
              </li> : (roleId === 3 && billingTypeId === "2") ? <li className="nav-item" role="presentation">
                <button type="button" className="nav-link active" role="tab" onClick={() => { setCreditedToOrBy("credited_by"); setHasMoreData(true); setCurrentSize(10); setSelectedRoleTabId(roleId === userRoleConstants.ADMIN_ID ? 2 : 1); }} data-bs-toggle="tab" data-bs-target="#navs-pills-top-retailer" tabIndex={-1} >
                  <i className={(roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) ? "ti ti-building-store me-1" : roleId === userRoleConstants.ADMIN_ID ? "ti ti-users me-1" : "ti ti-wallet me-1"} /> {(roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) ? "Retailer" : roleId === userRoleConstants.ADMIN_ID ? "Distributor" : "Wallet"}
                </button>
              </li> : <><li className="nav-item" role="presentation">
                <button type="button" className="nav-link active" role="tab" onClick={() => { setCreditedToOrBy("credited_to"); setHasMoreData(true); setCurrentSize(10); billingTypeId === "1" ? setPaymentTypeTabId(1) : billingTypeId === "2" ? setPaymentTypeTabId(2) :setPaymentTypeTabId(1); setSelectedRoleTabId(0); }} data-bs-toggle="tab" data-bs-target="#navs-pills-top-shaad" >
                  <i className={roleId !== userRoleConstants.RETAILER_ID ? "ti ti-user me-1" : "ti ti-credit-card me-1"} /> {roleId !== userRoleConstants.RETAILER_ID ? "Shaad" : "Credit"}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button type="button" className="nav-link" role="tab" onClick={() => { setCreditedToOrBy("credited_by"); setHasMoreData(true); setCurrentSize(10); billingTypeId === "1" ? setPaymentTypeTabId(1) : billingTypeId === "2" ? setPaymentTypeTabId(2) :setPaymentTypeTabId(1); setSelectedRoleTabId(roleId === userRoleConstants.ADMIN_ID ? 2 : 1); }} data-bs-toggle="tab" data-bs-target="#navs-pills-top-retailer" tabIndex={-1} >
                  <i className={(roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) ? "ti ti-building-store me-1" : roleId === userRoleConstants.ADMIN_ID ? "ti ti-users me-1" : "ti ti-wallet me-1"} /> {(roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) ? "Retailer" : roleId === userRoleConstants.ADMIN_ID ? "Distributor" : "Wallet"}
                </button>
              </li></>}
            </ul>
            <div className="tab-content p-0 credit-tabs">
              <div className="tab-pane fade show active" id="navs-pills-top-shaad" role="tabpanel"></div>
            </div>
          </div>
        </div>
      </div>
      <div className={roleId !== userRoleConstants.RETAILER_ID && ((roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId !== 0) || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) ? "d-flex justify-content-between mb-3" : "justify-content-end align-items-center credit-filter-shaadreports show"}>
        {roleId !== userRoleConstants.RETAILER_ID && ((roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId !== 0) || roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (
          <ul className="nav nav-pills mb-0 border-bottom-0 pb-0 shaad-distributor-tabs" role="tablist">
          {(selectedRoleTabId === 1 && (roleId === 2 || roleId === 5)) || (selectedRoleTabId === 2 && roleId === 1)  ? (
            <>
              <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link shaadcredit-pill ${paymentTypeTabId === 1 ? 'active' : ''}`}
                role="tab"
                onClick={() => { setHasMoreData(true); setCurrentSize(10); setPaymentTypeTabId(1); }}
                data-bs-toggle="tab"
                data-bs-target="#navs-pills-top-shaadcredit"
              >
                <i className="ti ti-credit-card me-1" /> Credit
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link shaadwallet-pill ${paymentTypeTabId === 2 ? 'active' : ''}`}
                role="tab"
                onClick={() => { setHasMoreData(true); setCurrentSize(10); setPaymentTypeTabId(2); }}
                data-bs-toggle="tab"
                data-bs-target="#navs-pills-top-shaadwallet"
              >
                <i className="ti ti-wallet me-1" /> Wallet
              </button>
            </li>
            </>
          ) : billingTypeId === "1" ? 
          <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link shaadcredit-pill ${paymentTypeTabId === 1 ? 'active' : ''}`}
                role="tab"
                onClick={() => { setHasMoreData(true); setCurrentSize(10); setPaymentTypeTabId(1); }}
                data-bs-toggle="tab"
                data-bs-target="#navs-pills-top-shaadcredit"
              >
                <i className="ti ti-credit-card me-1" /> Credit
              </button>
            </li> : billingTypeId === "2" ? 
              <li className="nav-item" role="presentation">
              <button
                type="button"
                className={`nav-link shaadwallet-pill ${paymentTypeTabId === 2 ? 'active' : ''}`}
                role="tab"
                onClick={() => { setHasMoreData(true); setCurrentSize(10); setPaymentTypeTabId(2); }}
                data-bs-toggle="tab"
                data-bs-target="#navs-pills-top-shaadwallet"
              >
                <i className="ti ti-wallet me-1" /> Wallet
              </button>
            </li> : ""
          }
        </ul>        
        )}
        <div className="justify-content-end align-items-center mb-2 credit-filter-shaadreports show">
          <BillingFilterModal billingFilterShow={billingFilterShow} setBillingFilterShow={setBillingFilterShow} selectedRoleTabId={selectedRoleTabId} paymentTypeTabId={paymentTypeTabId} roleId={roleId} userRoleConstants = {userRoleConstants} creditedToOrBy = {creditedToOrBy} currentSize={currentSize} transactionId={transactionId} setTransactionId={setTransactionId} setAmount={setAmount} amount={amount} requestDate={requestDate} setRequestDate={setRequestDate} submitRequestDate={submitRequestDate} setSubmitRequestDate={setSubmitRequestDate} creditDate={creditDate} setCreditDate={setCreditDate} submitCreditDate={submitCreditDate} setSubmitCreditDate={setSubmitCreditDate} dueDate={dueDate} setDueDate={setDueDate} submitDueDate={submitDueDate} setSubmitDueDate={setSubmitDueDate} paymentDate={paymentDate} setPaymentDate={setPaymentDate} submitPaymentDate={submitPaymentDate} setSubmitPaymentDate={setSubmitPaymentDate} credit_status_type_id={credit_status_type_id} setStatus={setStatus} retailerName={retailerName} setRetailerName={setRetailerName} accountNumber={accountNumber} setAccountNumber={setAccountNumber} distributorName={distributorName} setDistributorName={setDistributorName} />
          <button type="submit" className="btn btn-sm btn-secondary rounded-pill btn-smwaves-effect waves-light" onClick={handleReportDownload}>
            <i className="ti ti-download me-1" />
            Reports
          </button> 
        </div>
      </div>
    </>
  );
};

export default BillingHeader;
