import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { getSessionId } from "../../services/Customer";
import { loadStripe } from "@stripe/stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { errorToast } from "../Toasts/Toasts";

const SelectPaymentMethod = ({ showBox, setShowBox, setSelectedValue, selectedValue, amount, carrierId }) => {

  const navigate = useNavigate();
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSubmit = async () => {
    const stripe = await loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
    const response = await getSessionId({ amount: amount ?? 0, carrierId: carrierId });
    const result = stripe.redirectToCheckout({
      sessionId: response?.data?.session_id,
    });
    setShowBox(false);
    if (result.error) {
      console.log(result.error);
    }
  };

  return (
    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID }}>
      <Modal show={showBox} size="sm" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <div className="text-left mb-4">
                <h5 className="mb-2">Select Your Payment Method</h5>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between mb-3">
                  <img src="../../assets/img/paypal.svg" alt="PayPal" />
                  <input type="radio" name="payment" value="1" checked={selectedValue === "1"} onChange={handleChange} />
                </div>
                <hr style={{ color: "var(--toastify-color-info)" }} />
                <div className="d-flex justify-content-between">
                  <img src="../../assets/img/stripe.svg" alt="Stripe" />
                  <input type="radio" name="payment" value="0" checked={selectedValue === "0"} onChange={handleChange} />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center mt-4">
                  {selectedValue === "1" && (
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      fundingSource="paypal"
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: amount, // Specify the amount here
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          setShowBox(false);
                          let transactionId = details.purchase_units[0].payments.captures[0].id;
                          navigate('/customer/profile',{ state: { session_id: transactionId, carrierId: carrierId } });
                          
                        });
                      }}
                      onError={(err) => {
                        errorToast('Unable to process currency in decimals. Contact Administrator');
                        navigate('/customer/profile');
                      }}
                    />
                  )}
                  {selectedValue === "0" && (
                    <button type="submit" id="saveButton" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" onClick={handleSubmit}>
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </PayPalScriptProvider>
  );
};

export default SelectPaymentMethod;
