export default class ApiConstants {
  static USER_LOGIN = "login";
  static USER_FILTER = "users"
  static TRANSACTION_ADD = "transactions"
  static GET_TRANSACTION_FILTER = "transactions"    
  static USER_FORGOT_PASSWORD = "forgot-password";
  static USER_VERIFY_OTP = "verify-otp";
  static USER_SET_PASSWORD = "set-password";
  static USER_RESEND_OTP = "resend-otp";    
  static USER = "users";
  static USER_PROFILE = "users/profile";
  static GET_TOP_UP_FILTER = "topups";
  static GET_TRANSACTION_CONVERT_CURRENCY = "transactions/convert-currency";
  static MASTER_DATA = "master-data";
  static TRANSACTIONS_TOP_UP_REQUEST = "topups/request";
  static TRANSACTIONS_FINANCIAL_SUMMARY = "transactions/retailer/financial-summary";
  static NOTIFICATION = "notifications";
  static PRODUCT_FILTER = "products";
  static TRANSACTIONS_FLASH_CARDS_CURRENCY  = "transactions/flash-cards/currency";
  static PRODUCT = "products";
  static MARK_ALL_READ = "notifications/read-all";
  static USERS_ACCOUNT_NO = "users/account-no";
  static PRODUCTS_RETAILER = "products/retailer";
  static COMMISSION_HISTORY = "products/commission-history";
  static TRANSACTION_REQUEST = "transactions/request";
  static CUSTOMERS = "admin/customers";
  static ADMIN = "admin";
  static DASHBOARD = "dashboard";
  static CONVERT_CURRENCY = "customers/convert-currency";
  static SESSION_ID_REQUEST = "customers/create-checkout-session";
  static PAYMENT_GATEWAY_TRANSACTION_ID = "customers/checkout/sessions";
  static STORE_CUSTOMER_TRANSACTION = "customers/store-customer-transaction-details";
  static REGISTER_CUSTOMER = "register/customer";
  static CUSTOMER = "customers";
  static RECHARGE_REQUEST = "customers/recharge-request";
  static TOKEN_VERIFICATION = "customers/verify-recharge-request";
  static AGGREGATOR_STATUS = "admin/aggregator-status";;
  static GET_REFUND = "customers/refund/";
  static RECHARGE_REQUEST_DETAILS = "customers/rechargeRequestDetails";
  static ACCEPT_REJECT_RECHARGE_REQUEST = "customers/recharge-request";
  static QUERY_CATEGORIES = "help-and-Support/query-categories-tags";
  static QUERY = "help-and-Support/queries"
  static HELP_AND_SUPPORT = "help-and-support";
  static REQUEST_FOLLOW_UP = "help-and-support/follow-up-request";
  static GET_QUERY_BY_ID = "help-and-support/customer";
  static STATUS_UPDATE = "help-and-support/query/status";
  static QUERY_RESPONSE = "help-and-Support/query-response";
  static RECEIPT_DOWNLOAD = "customers/history/export";
  static GET_REPORT = "transactions/export";
  static PRODUCT_IMPORT = "products/import";
  static LOG_TRANSACTION = "admin/topups"
}

