import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import BillingTransactionUpdateModal from "../ManageBilling/BillingTransactionUpdateModal";
import { creditStatusType } from "../../../utils/constants";
import { fetchUserTransaction } from "../../../services/transactionService";
import { errorToast } from "../../Toasts/Toasts";

const CreditLog = ({ userDetails, acc_no }) => {
  const [loading, setLoading] = useState(true);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateType, setUpdateType] = useState("Approve");
  const [transctionId, setTransactionId] = useState("");
  const [transactionDetails, setTransactionDetails] = useState("");
  const [reRender, setReRender] = useState(false)
  const page = 1;
  const currentSize = 2;
  
  useEffect(() => {
    if (userDetails?.record?.id) {
      fetchTransactionDetails();
    }
    // eslint-disable-next-line
  }, [userDetails,reRender]);

  const fetchTransactionDetails = async () => {
    try {
      let response = await fetchUserTransaction({ id: userDetails?.record?.id, page: page, size: currentSize });
      setLoading(false);
      setTransactionDetails(response?.data);
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const statusMapping = {
    Pending: "Payment Pending",
    Requested: "Credit request sent",
    Approved: "Credit approved",
    Paid: "Payment Paid",
    Declined: "Payment Declined",
    default: "Payment Unpaid",
  };

  const handleShowModal = (data) => {
    setShowUpdateModal(!showUpdateModal);
    setUpdateType(data.type);
    setTransactionId(data?.transaction_id);
  };

  return (
    <>
      <div className="card mt-3">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title m-0 me-2 pt-1 mb-2 d-flex align-items-center">
            <i className="ti ti-list-details ms-n1 me-2" /> Credit Log
          </h5>
          <div>
            <Link to="/billing">
              View All <i className="ti ti-chevron-right" />
            </Link>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <span>Loading...</span>
          ) : (
            <ul className="timeline ms-1 mb-0">
              {transactionDetails?.length !== 0 ? (
                transactionDetails?.map((transactionDetail, index) => (
                  <li className="timeline-item timeline-item-transparent ps-4" key={index}>
                    <span className="timeline-point timeline-point-secondary" />
                    <div className="timeline-event">
                      <div className="timeline-header">{statusMapping[transactionDetail?.credit_status_type_name] || statusMapping.default} </div>
                      <p className="mb-2 small">
                        {" "}
                        {moment(transactionDetail?.updatedAt, moment.ISO_8601).format("DD MMM YY,  hh:mm A")} <span className="text-warning">by {transactionDetail?.name}</span>{" "}
                      </p>
                      <div className="col-12 text-end mt-2">
                        {transactionDetail?.credit_status_type_name === "Pending" || transactionDetail?.credit_status_type_name === "Requested" ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary rounded-pill me-sm-3 me-1"
                              onClick={() => {
                                handleShowModal({ type: "Approve", transaction_id: transactionDetail?.transaction_id });
                              }}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-label-secondary rounded-pill btn-reset"
                              onClick={() => {
                                handleShowModal({ type: "Decline", transaction_id: transactionDetail?.transaction_id });
                              }}
                            >
                              Decline
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li>No record Found</li>
              )}
            </ul>
          )}
        </div>
      </div>
      {showUpdateModal && <BillingTransactionUpdateModal showUpdateModal={showUpdateModal} updateType={updateType} setShowUpdateModal={setShowUpdateModal} transctionId={transctionId} creditStatusType={creditStatusType} page={page} currentSize={currentSize}  setReRender={setReRender} reRender={reRender}/>}
    </>
  );
};

export default CreditLog;
