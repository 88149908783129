import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { userLogin } from "../../../services/userService";
import { setLogin } from "../../../redux/features/authSlice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../Toasts/Toasts";
import encrypt from "../../../utils/encrypt";
import { userRoleConstants } from "../../../utils/constants";
import decrypt from "../../../utils/decrypt";

function Login() {
  const {DISTRIBUTOR_ID, RETAILER_ID, ADMIN_ID, DISTRIBUTOR_USER_ID, CUSTOMER_ID} = userRoleConstants
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const rechargeRequestId = params.get('isRechargeRequest');
 
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      let obj  = {
        password : encrypt(data?.password),
        userEmailOrPhone : data?.userEmailOrPhone,
        loginType:determineLoginType(location.pathname)
      }
      
      let response = await userLogin(obj);
       if (response?.success) {
        const roleId = parseInt(decrypt(response?.role_id));
        localStorage.setItem("token", response?.token);
        localStorage.setItem("roleId", response?.role_id);
     
        dispatch(
          setLogin({
            isAuthenticated: true,
            roleId: response?.role_id
          })
        );
        
        if (roleId === DISTRIBUTOR_ID  || roleId === ADMIN_ID || roleId === DISTRIBUTOR_USER_ID) {
          navigate("/dashboard");
        } else if (roleId === RETAILER_ID) {
          navigate(`/b2c-topup`);
        } else if(roleId === CUSTOMER_ID) {
          if(rechargeRequestId) {
            navigate("/customer/recharge-request");
            localStorage.setItem('rechargeRequestStatus',rechargeRequestId);
          } else {
            navigate("/customer/profile");
          }
        } else {
          navigate("/");
        }  
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message ?? "Something went wrong");
    }
  };
  
  
  const determineLoginType = (pathname) => {
    return pathname.startsWith("/customer/login") || window.location.host.indexOf('b2c') !== -1 ? "customer" : "user";
  };

  const determineForgotPasswordLink = (pathname) => {
    return pathname.startsWith("/customer/login") || window.location.host.indexOf('b2c') !== -1 ? "/customer/forgot-password" : "/forgot-password";
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
              {/* Login */}
              <div className="card card-topup bg-outline-primary mt-4">
                <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                  <div className="w-px-400 mx-auto">
                    <div className="card-body">
                      <h3 className="mb-5 text-center">Login to continue</h3>
                      <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                          <label htmlFor="userEmailOrPhone" className="form-label">
                            Email / Mobile
                          </label>
                          <input type="text" className={`form-control rounded-pill ${errors.userEmailOrPhone ? "is-invalid" : ""}`}
                            id="userEmailOrPhone"
                            {...register("userEmailOrPhone", {
                              required: "This field is required",
                              validate: (value) => {
                                const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
                                const isPhoneNumber = /^\+\d{1,20}$/.test(value);

                                return isEmail || isPhoneNumber || "Invalid email or Phone Number. For phone numbers, it should start with '+' and contain only digits.";
                              },
                            })}
                            placeholder="Enter your email or mobile"
                            autoComplete="off"
                          />
                          {errors.userEmailOrPhone && <div className="text-danger">{errors.userEmailOrPhone.message}</div>}
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <Link to={determineForgotPasswordLink(location.pathname)}>
                              <small>Forgot Password?</small>
                            </Link>
                          </div>
                          <div className="input-group input-group-merge rounded-pill">
                            <input type={showPassword ? "text" : "password"} className={`form-control ${errors.password ? "is-invalid" : ""}`}
                              id="password"
                              {...register("password", {
                                required: "This field is required",
                              })}
                              placeholder=".........."
                              autoComplete="off"
                            />
                            <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                              <i className={`ti ti-eye${showPassword ? "" : "-off"}`} />
                            </span>
                          </div>
                          {errors.password && <div className="text-danger">{errors.password.message}</div>}
                        </div>
                        <button id="btnLogin" type="submit" className="btn btn-primary w-100 text-white d-grid rounded-pill">
                          Login
                        </button>
                        {location.pathname.startsWith("/customer/login") ? (
                          <p className="mt-4 mb-0 text-center">
                            Don't have an account yet?{" "}
                            <Link to="/customer/register">Register here</Link>
                          </p> 
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SelectPaymentMethod showBox= {showBox} setShowBox= {setShowBox} setSelectedValue={setSelectedValue} selectedValue={selectedValue} amount={amount} carrierId= {carrierId} /> */}
    </>
  );
}

export default Login;
