import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { userVerifyOtp, userResendOtp } from "../../../services/userService";
import { errorToast, successToast } from "../../Toasts/Toasts";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(180);
  const [userEmailOrPhone, setUserEmailOrPhone] = useState("");
  const [isTimerEnded, setIsTimerEnded] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  let otpCountdown = null;

  useEffect(() => {
    setUserEmailOrPhone(location.state?.userEmailOrPhone);
    startTimer();

    return () => clearInterval(otpCountdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const startTimer = () => {
    otpCountdown = setInterval(() => {

      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(otpCountdown);
          setIsTimerEnded(false);
          return 0;
        }
      });

    }, 1000);
  };

  const handleResend = async () => {
    setTimer(180);
    setOtp("");
    setIsTimerEnded(true);

    try {
      let response = await userResendOtp({ userEmailOrPhone: userEmailOrPhone , loginType : location.state?.loginType});
      if (response?.success) {
        startTimer();
        successToast(response?.message);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.message);
    }
  };

  const onSubmit = async () => {
    try {
      setBtnDisabled(true);
      
      let otpValues = {
        userEmailOrPhone: userEmailOrPhone,
        otp: otp,
        loginType : location.state?.loginType
      };
      
      let response = await userVerifyOtp(otpValues);
      
      if (response?.success) {
        successToast(response?.message);
        // navigate("/account-security", { state: { userEmailOrPhone: userEmailOrPhone, resetToken: response?.token, loginType : location.state?.loginType } });
        let verifyOtpPath = "";
        if (location.pathname.startsWith("/customer/verify-otp")) {
          verifyOtpPath = "/customer/account-security";

        } else if(location.pathname.startsWith("/customer/register/verify-otp")) {
          verifyOtpPath = "/customer/register/account-security";

        } else if(location.pathname.startsWith("/customer/register/phone-number-verify-otp")) {
          verifyOtpPath = "/customer/register/account-security";

        } else {
          verifyOtpPath="/account-security"
        }

        // Navigate to the appropriate path
        navigate(verifyOtpPath, { state: { userEmailOrPhone: userEmailOrPhone, resetToken: response?.token, loginType : location.state?.loginType } });
  
      } else {
        errorToast(response?.message);
      }
      
      setBtnDisabled(false);
    } catch (error) {
      setBtnDisabled(false);
      errorToast(error?.message);
    }
  };

  // let splitEmail = userEmailOrPhone?.split("");
  // const indexOf = userEmailOrPhone?.indexOf("@");

  // splitEmail?.forEach((char, index) => {
  //   if (index !== 0) {
  //     if (index < indexOf) {
  //       splitEmail[index] = "*";
  //     }
  //   }
  // });
  let splitEmail = '';
  let indexOfAt = userEmailOrPhone?.indexOf('@');
  if (indexOfAt !== -1) { // If '@' is present, treat it as an email
    splitEmail = userEmailOrPhone?.split("");
    splitEmail?.forEach((char, index) => {
      if (index !== 0 && index < indexOfAt) {
        splitEmail[index] = "*";
      }
    });
  } else { // If '@' is not present, treat it as a phone number
    const phoneNumber = userEmailOrPhone;
    const lastFourDigits = phoneNumber?.slice(-4);
    const obscuredDigits = '*'.repeat(Math?.max(0, phoneNumber?.length - 4)); // Ensure count value is non-negative
    splitEmail = obscuredDigits + lastFourDigits;
  }

  const loginHere = (pathname) => {
    if (pathname.startsWith("/customer/verify-otp")) {
      return "/customer/forgot-password";
    } else if (pathname.startsWith("/customer/register/verify-otp")) {  
      return "/customer/register/request-otp";
    } else if (pathname.startsWith("/customer/register/phone-number-verify-otp")) {
      return "/customer/register/request-otp-phone-number";
    } else {
      return "/forgot-password";
    }
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
            <div className="card card-topup bg-outline-primary mt-4">
              <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                <div className="w-px-400 mx-auto">
                  <div className="card-body">
                    <h3 className="text-center">Enter OTP!</h3>
                    <p className="fs-6 mb-4 d-flex justify-content-between">
                      <span>
                        {/* Message has been sent to your <small>{splitEmail?.join("")}</small> */}
                        Message has been sent to your <small>{splitEmail}</small>
                      </span>
                      <Link to={loginHere(location.pathname)}>
                        <i className="ti ti-edit" />
                      </Link>
                    </p>
                    <form>
                      <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                        <OtpInput value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span> </span>} renderInput={(props) => <input {...props} />} inputStyle="my-2 me-3 text-center form-control rounded otp-input-width" />
                      </div>
                      <div className="d-flex justify-content-between">
                        <span>
                          <small>
                            <span id="countdowntimer">{timer}</span> Seconds
                          </small>
                        </span>
                        <button type="button" onClick={handleResend} className="btn d-grid text-blue" disabled={isTimerEnded}>
                          Resend
                        </button>
                      </div>
                      <div className="mt-4">
                        <button type="button" className="btn btn-primary w-100 rounded-pill text-white d-grid" onClick={onSubmit} disabled={btnDisabled}>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
