import React from "react";
import localStorageStore from "../../../utils/localStorageStore";
import { userRoleConstants } from "../../../utils/constants";
import { formatCreditBalance } from "../../../utils/sharedFunctions";

const RetailerDetails = ({ userDetails }) => {

  // Split the string into words
  let words = userDetails?.name?.split(" ");
  let initials = words?.map(word => word?.charAt(0));
  let result = initials?.join(""); 

  const RoleId = parseInt(localStorageStore.getRole());

  const getSuffix = (day) => {
    const lastDigit = day?.slice(-1); // Get the last digit of the day

    // Check if the last digit is 1, 2, or 3 and return the corresponding suffix
    if (lastDigit === "1") {
      return "st";
    } else if (lastDigit === "2") {
      return "nd";
    } else if (lastDigit === "3") {
      return "rd";
    } else {
      return "th";
    }
  };
 
  if (!userDetails) {
    return null; // or you can return some placeholder content
  }
  
  return (
    <>
      <div key={userDetails?.id} className="col-lg-4 col-sm-6 mb-4">
        <div className="card card-border-shadow-primary h-100">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center pb-1">
                    <div className="avatar me-2">
                      <span className="avatar-initial rounded bg-label-primary">{result}</span>
                    </div>
                    <div>
                      <h4 className="ms-1 mb-0">{userDetails?.name}</h4>
                      {RoleId === userRoleConstants.ADMIN_ID && (<span className="ms-1"><small>Distributor User: <span className="text-info">{userDetails?.total_distributor_users}</span></small></span>)}
                    </div>
                  </div>
                </div>
                <p className="mb-1 mt-2">
                  Account No.: <span className="fw-medium">{userDetails?.account_no}</span>
                </p>
                <p className="mb-0">
                  <small className="text-muted">
                    <i className="ti ti-phone ti-xs" />
                  </small>
                  <span className="fw-medium me-1">{userDetails?.phone_number}</span>
                </p>
                <p className="mb-0">
                  <small className="text-muted">
                    <i className="ti ti-map-pin ti-xs" />
                  </small>
                  <span className="fw-medium me-1">{userDetails?.address}</span>
                </p>
              </div>
              <div className="col-lg-4 text-end">
                <div>
                  {parseInt(userDetails?.credit_day) !== 0 &&( <><h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0 text-warning pb-0">{userDetails?.credit_day}<sup>{getSuffix(userDetails?.credit_day)}</sup></h5>
                  <small className="text-muted">Credit Day</small></>)}
                </div>
                <div className="mt-2">
                  <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0 text-primary pb-0">{userDetails?.billing_type_name}</h5>
                  <small className="text-muted">Billing Type</small>
                </div>
                <div className="mt-2">
                  {(userDetails && userDetails?.credit_limit) !== 0  && ( <><h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0 text-primary pb-0">{formatCreditBalance(userDetails.credit_limit)}</h5>
                  <small className="text-muted">Credit Limit</small></>)}
                </div>        
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailerDetails;

