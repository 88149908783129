import React from "react";
import { Link } from "react-router-dom";

const CustomerTopUpHeader = () => {
  const handleRechargeRequestStatus = () => {
    localStorage.setItem("rechargeRequestStatus", "0");
  };

  return (
    <>
      <nav className="layout-navbar shadow-none py-0">
        <div className="container">
          <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4 mt-0">
            <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">
              <Link to="https://shaadonline.com/" className="app-brand-link" onClick={handleRechargeRequestStatus}>
                <img src="/assets/img/logo.png" alt="" width="140px" />
              </Link>
            </div>
            <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
              <button className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="ti ti-x ti-sm" />
              </button>
            </div>
            <div className="landing-menu-overlay d-lg-none" />
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <Link className="nav-link dropdown-toggle hide-arrow" to="#">
                About Us
              </Link>

              <li className="d-flex ">
                <Link className="nav-link dropdown-toggle hide-arrow" to={`/customer/login?isRechargeRequest=1`}>
                  Recharge Request
                </Link>
                <Link to="/customer/login" className="btn btn-sm btn-secondary rounded-pill me-2">
                  Login
                </Link>
                <Link to="/customer/register" className="btn btn-sm btn-primary rounded-pill">
                  Register
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default CustomerTopUpHeader;
