import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerProfileApi } from "../../../redux/features/customerProfileSlice";
import { errorToast, successToast } from "../../Toasts/Toasts";
import Swal from "sweetalert2";
import moment from "moment";
import { deleteCustomer } from "../../../services/customerService";
import { socket } from "../../../utils/socket";
import { notificationApi } from "../../../redux/features/notificationSlice";
import { approveRejectRechargeRequest, rechargeRequestDetails } from "../../../services/Customer";
import CustomerModal from "../../Shared/HelpAndSupport/CustomerModal";
import { setLogout } from "../../../redux/features/authSlice";

const Header = () => {
  const [id, setUserId] = useState("");
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCustomerSupportModal, setShowCustomerSupportModal] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [ticketId ,setTicketId] = useState(0)
  const [typeId, setTypeId] = useState(0);
  const [parsedContactData, setParsedContactData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.removeItem("token");
    localStorage.removeItem("roleId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedContactData");
    localStorage.removeItem("userId");
    localStorage.removeItem("ContactId");
    localStorage.removeItem("contactDetailsAfterLogin");
    localStorage.removeItem("profileData");
    localStorage.removeItem("rechargeRequestStatus");
    localStorage.removeItem("rechargeDetails");
    navigate("/customer/login");
    localStorage.removeItem('selectedContactIndex');
  };

  useEffect(() => {
    const fetchNotificationDetails = async () => {
      await dispatch(notificationApi());
    };

    if (isAuthenticated) {
      dispatch(getCustomerProfileApi());
      fetchNotificationDetails();
    }

    // eslint-disable-next-line
  }, []);

  let userProfileData = useSelector((state) => state.customerProfile.customerDetails);
  localStorage.setItem("userId", userProfileData?.id);
  const userProfileDataJSON = JSON.stringify(userProfileData);
  localStorage.setItem("profileData", userProfileDataJSON );

  useEffect(()=>{
      setUserId(userProfileData?.id)
  },[userProfileData])

  // Split the string into words
  let words = userProfileData?.name?.split(" ");
  let initials = words?.map((word) => word?.charAt(0));
  let result = initials?.join("");
  
  async function handleShowDecline() {
    try {
      const result = await Swal.fire({
        text: `Are you sure? This will delete all your data.`,
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes, Terminate",
        cancelButtonText: "No, Cancel",
        customClass: {
          confirmButton: "btn  btn-primary me-3",
          cancelButton: "btn  btn-label-secondary",
          content: "info text-primary",
        },
      });

      if (result?.value === true) {
        const response = await deleteCustomer();
        if (response?.success === true) {
          successToast(response?.message);
          handleLogout();
        }
      }
    } catch (error) {
      // Handle errors
      errorToast(error?.response?.data?.message || "An error occurred while delete customer.");
    }
  }

  const rechargeRequestStatus = () => {
    localStorage.setItem("rechargeRequestStatus", "1")
  }

  const handleRechargeRequestStatus = () => {
    localStorage.setItem("rechargeRequestStatus", "0");
  };

  useEffect(() => {
    const fetchContactData = async () => {
      const ContactData = localStorage.getItem("selectedContactData");
      const parsedContactData = JSON.parse(ContactData);
      if (parsedContactData === null || Object.keys(parsedContactData).length === 0) {
        setTimeout(fetchContactData, 1000); // Retry after 1 second if parsedContactData is still null or empty
      } else {
        setParsedContactData(parsedContactData);
      }
    };
    fetchContactData();

  }, []);

  useEffect(() => {
    try {
      if (userProfileData?.id) {
        socket.connect();
        socket.on("connect", () => {
          const userIdWithSuffix = `${id}_customer`; 
          socket.emit("mapUser", { sid: socket.id, user_id: userIdWithSuffix });
        });
      }

      socket.on("notification", (data) => {
        try {
          dispatch(notificationApi());
          if (id) {
            successToast(data?.notification);
          }
        } catch (error) {
          errorToast(error.message || "An error occurred");
        }
      });

      return () => {
        socket.disconnect("notification");
      };
    } catch (error) {
      errorToast(error.message || "An error occurred during initialization");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const response = useSelector((state) => state.notification.notificationDetails);

  useEffect(() => {
    setNotificationDetails(response);
  }, [response]);

  const handleProceed = async (rechargeRequestId) => {
    localStorage.setItem("rechargeRequestId", rechargeRequestId);

    try {
      const fetchedDetails = await rechargeRequestDetails(rechargeRequestId);

      const fetchedDetailsJSON = JSON.stringify(fetchedDetails?.data);

      const userId = localStorage.getItem("userId");

      const rechargeDetails = {
        converted_currency: fetchedDetails?.data?.converted_currency,
        mobile_number: fetchedDetails?.data?.senderDetails?.phone_number,
        original_currency_type: fetchedDetails?.data?.source_currency,
        phone_code: fetchedDetails?.data?.senderDetails?.phone_code,
        service_fee: fetchedDetails?.data?.service_fee,
        converted_amount: fetchedDetails?.data?.converted_amount,
        source_amount: fetchedDetails?.data?.source_amount,
        total_converted_amount: fetchedDetails?.data?.total_converted_amount,
        total_source_amount: fetchedDetails?.data?.total_source_amount,
        vat: fetchedDetails?.data?.vat,
        recharge_request_id: rechargeRequestId,
        requested_by: userId,
      };

      const response = JSON.stringify(rechargeDetails);

      localStorage.setItem("rechargeDetails", response);

      localStorage.setItem("fetchedRechargeRequestDetails", fetchedDetailsJSON);

      localStorage.setItem("rechargeRequestStatus", 0);

      setShowDropdown(false);

      navigate("/customer/recharge");
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const handleCancel = async (rechargeRequestId) => {
    const obj = {
      recharge_request_id : rechargeRequestId,
      recharge_request_updated_status: 2
    }
    try {
      await approveRejectRechargeRequest(obj)

      setShowDropdown(false)
      dispatch(notificationApi());
    } catch(error) {
      errorToast(error?.response?.data?.message);
    }

  
  }

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown menu visibility
  };

  const handleShowCustomerSupport = (Id) => {
    setTypeId(Id)
    setShowCustomerSupportModal(!showCustomerSupportModal)
  }

  const handleQuery = (details) => {
    if ( details?.notification_type_id  === 12 || details?.notification_type_id === 13 ) {
      setShowCustomerSupportModal(!showCustomerSupportModal)
      setTypeId(details?.notification_type_id)
      setTicketId(details?.ticket_id)
    }
  }

  return (
    <>
      <nav className="layout-navbar shadow-none py-0">
        <div className="container">
          <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4 mt-0">
            <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">
              <Link to="https://shaadonline.com/" className="app-brand-link" onClick={handleRechargeRequestStatus}>
                <img src="/assets/img/logo.png" alt="" width="140px" />
              </Link>
            </div>
            <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
              <button className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="ti ti-x ti-sm" />
              </button>
            </div>
            <div className="landing-menu-overlay d-lg-none" />
            {isAuthenticated ? (
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                <li className="d-flex ">
                  {parsedContactData && (
                    <Link className="nav-link"  to= {`/customer/recharge-request`} onClick={rechargeRequestStatus}>
                    Recharge Request
                  </Link>
                  )}
                  
                </li>
                <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-1 me-xl-1">
                <Link className={`nav-link dropdown-toggle hide-arrow ${showDropdown ? 'show' : ''}`} to="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded={showDropdown} onClick={handleDropdownToggle} >
                  <i className="ti ti-bell ti-md" />
                  <span className="badge bg-danger rounded-pill badge-notifications">{notificationDetails?.length}</span>
                </Link>
                  <ul className={`dropdown-menu dropdown-menu-end py-0 ${showDropdown ? 'show' : ''}`} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    <li className="dropdown-menu-header border-bottom">
                      <div className="dropdown-header d-flex align-items-center py-3">
                        <h5 className="text-body mb-0 me-auto">Notifications</h5>
                      </div>
                    </li>
                    <li className="scrollable-container ps">
                      <ul className="list-group list-group-flush">
                        {notificationDetails?.map((details, index) => <li className="list-group-item list-group-item-action dropdown-notifications-item" key={index}>
                          <div className="d-flex" onClick={()=>{handleQuery(details)}}>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">{details?.notification_title}</h6>
                              <p className="mb-0">
                                {details?.notification} <small>{details?.notification_type_id === 6 ? "USD" : ""}</small>
                              </p>
                              <small className="text-muted">{moment(details?.updatedAt, moment.ISO_8601).format('DD MMM YY | hh:mm A') !== "Invalid date" ? moment.utc(details?.updatedAt, moment.ISO_8601).format('DD MMM YY , hh:mm A') : "--"}</small>
                            </div>
                          </div>
                          {details?.notification_type_id === 6 && <div className="col-12 text-end mt-2">
                            <Link to="#" onClick={()=>{handleCancel(details?.recharge_request_id)}} type="button" className="btn btn-sm btn-label-secondary rounded-pill btn-reset">
                              Cancel
                            </Link>
                            <Link to="#" onClick={()=>{handleProceed(details?.recharge_request_id)}} type="button" className="btn btn-sm btn-primary rounded-pill me-sm-3 me-1">
                              Proceed
                            </Link>
                          </div>}
                        </li>)}
                      </ul>
                      <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }} />
                      </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container ps border-top">
                      <ul className="list-group list-group-flush">
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <Link className="nav-link dropdown-toggle hide-arrow" to="#" data-bs-toggle="dropdown">
                    <div className="avatar">{userProfileData?.image ? <img src={userProfileData?.image} alt="" className="rounded-circle" /> : <span className="avatar-initial rounded bg-label-primary">{result}</span>}</div>
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <Link className="dropdown-item" to="/customer/profile">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar">{userProfileData?.image ? <img src={userProfileData?.image} alt="" className="rounded-circle" /> : <span className="avatar-initial rounded bg-label-primary">{result}</span>}</div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-medium d-block">Me</span>
                            <small>{userProfileData?.phone_code + userProfileData?.phone_number}</small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">
                        <i className="ti ti-help me-2 ti-sm" />
                        <span className="align-middle">FAQ</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" onClick={()=>{handleShowCustomerSupport(0)}} id="emailComposeSidebarLabel1">
                        <i className="ti ti-headset me-2 ti-sm" />
                        <span className="align-middle">Help &amp; Support</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item"  onClick={() => handleShowDecline()}>
                        <i className="ti ti-user-x me-2 ti-sm" />
                        <span className="align-middle">Terminate Account</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/customer/login" onClick={handleLogout}>
                        <i className="ti ti-logout me-2 ti-sm" />
                        <span className="align-middle">Log Out</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                <Link className="nav-link dropdown-toggle hide-arrow" to="#">
                  About Us
                </Link>

                <li className="d-flex ">
                  <Link className="nav-link dropdown-toggle hide-arrow" to={`/customer/login?isRechargeRequest=1`}>
                    Recharge Request
                  </Link>
                  <Link to="/customer/login" className="btn btn-sm btn-secondary rounded-pill me-2">
                    Login
                  </Link>
                  <Link to="/customer/register" className="btn btn-sm btn-primary rounded-pill">
                    Register
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
       {showCustomerSupportModal && isAuthenticated && <CustomerModal showCustomerSupportModal={showCustomerSupportModal} setShowCustomerSupportModal={setShowCustomerSupportModal} typeId={typeId} ticketId={ticketId} />}
      </nav>
    </>
  );
};

export default Header;
