import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { postUserFilterApi, setFilteredChips, clearFilteredChips } from "../../../redux/features/userSlice";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";

const RetailerFilterModal = ({ masterData, currentSize, clearFilterValues, acc_no, DistributorId }) => {
  let page = 1;
  const [retailerAccountNumber, setRetailerAccountNumber] = useState("");
  const [retailerName, setRetailerName] = useState("");
  const [retailerLocation, setRetailerLocation] = useState("");
  const [retailerOnBoardDate, setRetailerOnBoardDate] = useState("");
  const [retailerCommission, setRetailerCommission] = useState("");
  const [retailerStatus, setRetailerStatus] = useState("");
  const [retailerBillingType, setRetailerBillingType] = useState("");
  const [retailerCreditDay, setRetailerCreditDay] = useState("");
  const [retailerFilterModalShow, setRetailerFilterModalShow] = useState(false);
  const [users, setUsers] = useState("");
  const [retailerCount, setRetailerCount] = useState("");
  const [creditBalance, setCreditBalance] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  // const [retailerCreditBalance, setRetailerCreditBalance] = useState();

  const dispatch = useDispatch();
  const RoleId = parseInt(localStorageStore.getRole());

  useEffect(() => {
    const filterValue = () => {
      if (clearFilterValues === "account_no") setRetailerAccountNumber("");
      if (clearFilterValues === "name") setRetailerName("");
      if (clearFilterValues === "commission") setRetailerCommission("");
      if (clearFilterValues === "country_id") setRetailerLocation("");
      if (clearFilterValues === "createdAt") setRetailerOnBoardDate("");
      if (clearFilterValues === "is_active") setRetailerStatus("");
      if (clearFilterValues === "credit_day") setRetailerCreditDay("");
      if (clearFilterValues === "billing_type_id") setRetailerBillingType("");
      if (clearFilterValues === "users") setUsers("");
      if (clearFilterValues === "retailerCount") setRetailerCount("");
      if (clearFilterValues === "credit_balance") setCreditBalance("");
      if (clearFilterValues === "credit_limit") setCreditLimit("");
    };

    filterValue();

    // eslint-disable-next-line
  }, [clearFilterValues]);

  const filterData = {
    account_no: retailerAccountNumber !== "" ? retailerAccountNumber : "",
    name: retailerName !== "" ? retailerName : "",
    country_id: retailerLocation !== "" ? retailerLocation?.value : "",
    createdAt: retailerOnBoardDate !== "" ? retailerOnBoardDate : "",
    commission: retailerCommission !== "" ? retailerCommission : "",
    is_active: retailerStatus !== "" ? retailerStatus?.value : "",
    credit_day: retailerCreditDay !== "" ? retailerCreditDay?.value : "",
    billing_type_id: retailerBillingType !== "" ? retailerBillingType?.value : "",
    users: users !== "" ? users : "",
    retailerCount: retailerCount !== "" ? retailerCount : "",
    credit_balance: creditBalance !== "" ? creditBalance : "",
    credit_limit: creditLimit !== "" ? creditLimit : "",
  };

  const filterChipsData = {
    account_no: retailerAccountNumber !== "" ? retailerAccountNumber : "",
    name: retailerName !== "" ? retailerName : "",
    country_id: retailerLocation !== "" ? retailerLocation : "",
    createdAt: retailerOnBoardDate !== "" ? retailerOnBoardDate : "",
    commission: retailerCommission !== "" ? retailerCommission : "",
    is_active: retailerStatus !== "" ? retailerStatus : "",
    credit_day: retailerCreditDay !== "" ? retailerCreditDay : "",
    billing_type_id: retailerBillingType !== "" ? retailerBillingType : "",
    users: users !== "" ? users : "",
    retailerCount: retailerCount !== "" ? retailerCount : "",
    credit_balance: creditBalance !== "" ? creditBalance : "",
    credit_limit: creditLimit !== "" ? creditLimit : "",
  };

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(filterData);
  const filteredChipsData = filterEmptyFields(filterChipsData);

  const submitFilterData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every(([key, value]) => {
      if (key === "role_id") {
        return true;
      }
      return value === "";
    });

    if (!isFilterEmpty) {
      let filterData = {};
      let chipsData = {};

      if (acc_no) {
        filterData = { ...filteredData, user_id: DistributorId };
        chipsData = { ...filteredChipsData, user_id: DistributorId };
      } else if (RoleId === userRoleConstants.ADMIN_ID) {
        filterData = filteredData;
        chipsData = filteredChipsData;
      } else {
        filterData = { ...filteredData, role_id: userRoleConstants.RETAILER_ID };
        chipsData = { ...filteredChipsData, role_id: userRoleConstants.RETAILER_ID };
      }
      const response = await dispatch(postUserFilterApi({ filteredData: filterData, page, size: currentSize }));

      dispatch(setFilteredChips(Object.entries(chipsData)));

      if (response?.meta?.requestStatus === "fulfilled") {
        successToast("Filter applied successfully");
      } else {
        errorToast("Error occurred while filtering");
      }

      setRetailerFilterModalShow(!retailerFilterModalShow);
    } else {
      errorToast("No field selected for filter");
    }
  };

  const locationOptions = () => {
    return masterData?.countries?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const statusOption = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  const billingTypeOption = () => {
    return masterData?.billingType?.map((data) => ({
      label: data?.name,
      value: data?.billing_type_id,
    }));
  };

  const creditDayOption = Array.from({ length: 28 }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }));

  const handleEventReceiveDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setRetailerOnBoardDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelReceiveDate = (event, picker) => {
    picker.element.val("");
    setRetailerOnBoardDate("");
  };

  const handleResetButton = () => {
    setRetailerAccountNumber("");
    setRetailerCommission("");
    setRetailerLocation("");
    setRetailerName("");
    setRetailerStatus("");
    setRetailerOnBoardDate("");
    setRetailerBillingType("");
    setRetailerCreditDay("");
    setCreditBalance("");
    setUsers("");
    setRetailerCount("");
    setCreditLimit("");
    dispatch(clearFilteredChips());
    // acc_no ? dispatch(postUserFilterApi({ filteredData: {user_id : DistributorId}, page, size: currentSize })) : dispatch(postUserFilterApi({filteredData: {role_id : userRoleConstants.RETAILER_ID}, page, size: currentSize}));
    let filteredData = {};
    if (acc_no) {
      filteredData = { user_id: DistributorId };
    } else if (RoleId === userRoleConstants.ADMIN_ID) {
      filteredData = {};
    } else {
      filteredData = { role_id: userRoleConstants.RETAILER_ID };
    }

    // Make API call with the determined filtered data
    dispatch(postUserFilterApi({ filteredData, page, size: currentSize }));
    setRetailerFilterModalShow(!retailerFilterModalShow);
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light me-1" onClick={() => setRetailerFilterModalShow(true)}>
        <i className="ti ti-filter me-1"></i> Filter
      </button>
      {
        <Modal show={retailerFilterModalShow} size="lg" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <Modal.Body>
                <button type="button" className="btn-close" onClick={() => setRetailerFilterModalShow(!retailerFilterModalShow)}></button>
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-label">Account No.</label>
                    <input type="text" placeholder="" className="form-control mb-3" onChange={(e) => setRetailerAccountNumber(e.target.value)} value={retailerAccountNumber} />
                  </div>
                  <div className="col-lg-4">
                    {acc_no ? <label className="form-label">Retailer Name</label> : RoleId === userRoleConstants.ADMIN_ID ? <label className="form-label">Distributor Name</label> : <label className="form-label">Retailer Name</label>}
                    <input type="text" placeholder="" className="form-control mb-3" onChange={(e) => setRetailerName(e.target.value)} value={retailerName} />
                  </div>
                  {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID ) && (
                    <div className="col-lg-4">
                      <label className="form-label">Location</label>
                      <Select options={locationOptions()} isMulti={false} onChange={setRetailerLocation} value={retailerLocation} />
                    </div>
                  )}
                  {RoleId === userRoleConstants.ADMIN_ID && acc_no && (
                    <div className="col-lg-4">
                      <label className="form-label">Location</label>
                      <Select options={locationOptions()} isMulti={false} onChange={setRetailerLocation} value={retailerLocation} />
                    </div>
                  )}
                  <div className="col-lg-4">
                    <label className="form-label">Onboarding Date</label>
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
                        showDropdowns: true,
                      }}
                      onApply={handleEventReceiveDate}
                      onCancel={handleCancelReceiveDate}
                    >
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={retailerOnBoardDate} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  {!acc_no && (
                    <>
                      <div className="col-lg-4 mt-3 mt-lg-0 mb-3">
                        <label className="form-label">Credit Day</label>
                        <Select options={creditDayOption} isMulti={false} onChange={(selectedOption) => setRetailerCreditDay(selectedOption)} value={retailerCreditDay} />
                      </div>
                      <div className="col-lg-4 mt-3 mt-lg-0 mb-3">
                        <label className="form-label">Billing Type</label>
                        <Select options={billingTypeOption()} isMulti={false} value={retailerBillingType} onChange={setRetailerBillingType} />
                      </div>
                    </>
                  )}
                  {RoleId === userRoleConstants.ADMIN_ID && !acc_no && (
                    <div className="col-lg-4">
                      <label className="form-label">Credit Balance</label>
                      <input onChange={(e) => setCreditBalance(e.target.value)} value={creditBalance} type="number" placeholder="" className="form-control mb-3" min={0} />
                    </div>
                  )}
                  <div className="col-lg-4">
                    <label className="form-label">
                      Commission <small>(%)</small>
                    </label>
                    <input onChange={(e) => setRetailerCommission(e.target.value)} value={retailerCommission} type="number" placeholder="" className="form-control mb-3" min={0} />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">
                      Credit Limit
                    </label>
                    <input onChange={(e) => setCreditLimit(e.target.value)} value={creditLimit} type="number" placeholder="" className="form-control mb-3" min={0} />
                  </div>
                  {RoleId === userRoleConstants.ADMIN_ID && !acc_no && (
                    <>
                      <div className="col-lg-4">
                        <label className="form-label">User</label>
                        <input onChange={(e) => setUsers(e.target.value)} value={users} type="number" placeholder="" className="form-control mb-3" min={0} />
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">No. of Retailers</label>
                        <input onChange={(e) => setRetailerCount(e.target.value)} value={retailerCount} type="number" placeholder="" className="form-control mb-3" min={0} />
                      </div>{" "}
                    </>
                  )}
                  <div className="col-lg-4">
                    <label className="form-label">Status</label>
                    <Select options={statusOption} isMulti={false} onChange={(selectedOption) => setRetailerStatus(selectedOption)} value={retailerStatus} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" onClick={() => submitFilterData()} className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter">
                      Submit
                    </button>
                    <button onClick={handleResetButton} type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect">
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default RetailerFilterModal;
