import React from 'react'
import CreditLog from '../../Shared/CreditLog/Index'
import AddCredit from '../../Shared/AddCredit/Index'
import TransactionTable from '../../Shared/TransactionTable/Index'
import RetailerTable from '../../Distributor/RetailerTable'
import localStorageStore from "../../../utils/localStorageStore";
import { userRoleConstants } from "../../../utils/constants";

const ManageProfileDetails = ({userDetails, acc_no, setReRender, reRender}) => {
  const RoleId = parseInt(localStorageStore.getRole());
  
  return (
    <>
      <div className="row">
       {(RoleId === userRoleConstants.DISTRIBUTOR_ID ||RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) ? <TransactionTable acc_no={acc_no} />  :  <RetailerTable acc_no={acc_no} /> } 
        <div className="col-md-3">
          <AddCredit userDetails={userDetails?.record} setReRender={setReRender} reRender={reRender} acc_no={acc_no}/>
          <CreditLog userDetails={userDetails} acc_no={acc_no}/>
        </div>
      </div>  
    </>
    )
}

export default ManageProfileDetails