import { useState, useEffect } from "react";
import DetailsTable from "../DetailsTable/Index";
import StatusCard from "../StatusCard/SmallStatusCard";
import RetailerFilterModal from "./RetailerFilterModal";
import RetailerAddModal from "./RetailerAddModal";
import { getMasterData } from "../../../services/userService";
import { postUserFilterApi, setFilteredChips } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatCreditBalance } from "../../../utils/sharedFunctions";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";
import { Link, useParams } from "react-router-dom";
const Retailers = () => {

  const [userAllDetails, setUserAllDetails] = useState("");
  const [currentSize, setCurrentSize] = useState(10);
  const [retailerAddEditModalShow, setRetailerAddEditModalShow] = useState(false);
  const [retailerModalType, setRetailerModalType] = useState("");
  const [clearFilterValues, setClearFilterValues] = useState("");
  const [masterData, setMasterData] = useState("");
  const [id, setId] = useState();
  const dispatch = useDispatch();
  const filteredChips = useSelector((state) => state?.user?.filteredChips);
  const RoleId = parseInt(localStorageStore.getRole());
  const DistributorId = localStorage.getItem("distributorOrRetailerId");
  const [loading, setLoading] = useState(false);

  let page = 1;
  const { acc_no } = useParams();

  useEffect(() => {
    fetchMasterData(id ?? userAllDetails?.country_code);
    
    // eslint-disable-next-line
  }, [id, userAllDetails]);

  const fetchMasterData = async (countryCode) => {
    try {
      const response = await getMasterData(countryCode);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips.filter(([existingKey]) => existingKey !== key);

    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredChips(updatedChips));

    // Call the clearFilterValues function to clear the corresponding state values
    setClearFilterValues(key);

    // Prepare filteredData object based on the presence of acc_no
    let filteredData = Object.fromEntries(updatedChips);
    let transformedChips = {};

    for (let key in filteredData) {
      if (typeof filteredData[key] === "object" && "label" in filteredData[key] && "value" in filteredData[key]) {
        transformedChips[key] = filteredData[key].value;
      } else {
        transformedChips[key] = filteredData[key];
      }
    }

    if (acc_no) {
      // If acc_no exists, include user_id in filteredData
      transformedChips = { ...transformedChips, user_id: DistributorId };
    } else if (RoleId !== userRoleConstants.ADMIN_ID) {
      transformedChips = { ...transformedChips, role_id: userRoleConstants.RETAILER_ID };
    }

    // Call the product filter API with the updated filteredData
    dispatch(postUserFilterApi({ filteredData: transformedChips, page, size: currentSize + 10 }));
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let filteredData = {};

        if (acc_no) {
          filteredData = { user_id: DistributorId };
        } else if (RoleId === userRoleConstants.ADMIN_ID) {
          filteredData = {};
        } else {
          filteredData =  {role_id : userRoleConstants.RETAILER_ID}
        }

        const response = await dispatch(postUserFilterApi({ filteredData, page, size: currentSize }));

        if (response?.meta?.requestStatus === "fulfilled") {
          setLoading(false); // Set loading to false after API call succeeds
        }
      } catch (error) {
        console.error(error);
        setLoading(false); // Set loading to false if an error occurs during API call
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, acc_no]);

  const userDetails = useSelector((state) => state.user.userDetails);

  const dataForCards = useSelector((state) => state.user.count);  

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        {acc_no ? (
          <h4 className="mb-1 pt-1">
            {" "}
            <span className="text-muted fw-light">
              <Link to={`/distributor/${acc_no}/profile`}>Distributor's Profile</Link> /
            </span>{" "}
            Retailers List
          </h4>
        ) : RoleId === userRoleConstants.ADMIN_ID ? (
          <h4 className="mb-1 pt-1">Distributors</h4>
        ) : (
          <h4 className="mb-1 pt-1">Retailers</h4>
        )}
        <p>
          <RetailerFilterModal masterData={masterData} currentSize={currentSize} clearFilterValues={clearFilterValues} acc_no={acc_no} DistributorId={DistributorId} />
          {!acc_no && (
            <button
              type="submit"
              className="btn btn-sm btn-secondary rounded-pill btn-smwaves-effect waves-light"
              onClick={() => {
                setRetailerAddEditModalShow(true);
                setRetailerModalType("add");
              }}
            >
              <i className="ti ti-plus me-1"></i> Add
            </button>
          )}
        </p>
      </div>

      <div className="row">
        {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (
          <div className="col-lg-3 col-12 mb-4">
            <StatusCard color="primary" icon="ti ti-building-store ti-sm" value={formatCreditBalance(dataForCards?.total_retailers ?? 0)} label="Total Retailers" />
          </div>
        )}
        {RoleId === userRoleConstants.ADMIN_ID && !acc_no && (
          <div className="col-lg-3 col-12 mb-4">
            <StatusCard color="primary" icon="ti ti-building-store ti-sm" value={formatCreditBalance(dataForCards?.total_distributor ?? 0)} label="Total Distributors" />
          </div>
        )}
        {RoleId === userRoleConstants.ADMIN_ID && acc_no && (
          <div className="col-lg-3 col-12 mb-4">
            <StatusCard color="primary" icon="ti ti-building-store ti-sm" value={formatCreditBalance(dataForCards?.total_retailers ?? 0)} label="Total Retailers" />
          </div>
        )}
        <div className="col-lg-3 col-12 mb-4">
          <StatusCard color="info" icon="ti ti-wallet ti-sm" value={formatCreditBalance(dataForCards?.total_topup_amount ?? 0)} label="Total Top-Ups" currency_format="USD /" top_up_count={`Count: ${formatCreditBalance(dataForCards?.total_topup_count ?? 0)}`} />
        </div>
        <div className="col-lg-3 col-12 mb-4">
          {" "}
          <StatusCard color="warning" icon="ti ti-cash ti-sm" value={formatCreditBalance(dataForCards?.total_credit_balance ?? 0)} label="Credit Balance" currency_format="USD" />
        </div>
      </div>

      <DetailsTable filteredChips={filteredChips} handleChipRemove={handleChipRemove} userDetails={userDetails} setCurrentSize={setCurrentSize} currentSize={currentSize} masterData={masterData} setId={setId} acc_no={acc_no} DistributorId={DistributorId} tableLoading={loading} userAllDetails={userAllDetails} setUserAllDetails={setUserAllDetails}/>
      {retailerAddEditModalShow && <RetailerAddModal retailerModalType={retailerModalType} retailerAddEditModalShow={retailerAddEditModalShow} setRetailerAddEditModalShow={setRetailerAddEditModalShow} masterData={masterData} setId={setId} currentSize={currentSize} filteredChips={filteredChips}/>}
    </>
  );
};

export default Retailers;
