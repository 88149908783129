import React from "react";
import CustomerHeader from "./CustomerHeader";
import CustomerHistory from "./CustomerHistory";
import CustomerContactList from "./CustomerContactList";
import { useParams } from "react-router-dom";

const CustomerProfile = () => {
  const { id } = useParams();

  return (
    <div className="content-wrapper">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="pt-1 mb-1">Customer's Profile</h4>
      </div>
      <div className="row">
        <CustomerHeader id={id} />
      </div>
      <div className="row">
        <div className="col-md-9">
            <CustomerHistory id={id} />
        </div>
        <div className="col-md-3">
            <CustomerContactList id={id} />
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
