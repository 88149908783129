import React from "react";

const ComparisionCard = ({text, textClassName, totalBalance, currencyFormat, firstCarrierName, secondCarrierName, firstCarrierBalance, secondCarrierBalance}) => {
  return (
    <div className="card h-100">
      <div className="card-header pb-0">
        <div className="">
          <small className={textClassName}>{text}</small>
          <h5 className="mb-1">
          {totalBalance} <small>{currencyFormat}</small>
          </h5>
        </div>
      </div>
      <div className="card-body">
        <div className="row mt-4">
          <div className="col-4">
            <div className="d-flex gap-2 align-items-center mb-2">
              <p className="mb-0"> {firstCarrierName}</p>
            </div>
            <h5 className="mb-0 pt-1">{firstCarrierBalance}</h5>
          </div>
          <div className="col-4">
            <div className="divider divider-vertical">
              <div className="divider-text">
                <span className="badge-divider-bg bg-label-secondary">VS</span>
              </div>
            </div>
          </div>
          <div className="col-4 text-end">
            <div className="d-flex gap-2 justify-content-end align-items-center mb-2">
              <p className="mb-0"> {secondCarrierName}</p>
            </div>
            <h5 className="mb-0 pt-1">{secondCarrierBalance}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisionCard;
