import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userFilter } from "../../services/userService";

const initialState = {
  userDetails: [],
  isLoading: false,
  error: null,
  filteredChips: [],
  filteredUserChips: [],
  count: {},
};

export const postUserFilterApi = createAsyncThunk("user/postUseFilterApi", async (filteredData) => {
  try {
    const response = await userFilter(filteredData?.filteredData, filteredData?.page, filteredData?.size);
    return response
  } catch (error) {
    
    throw error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setInitUserDetail: (state) => {
      state.userDetails = [];
    },
    setFilteredChips: (state, action) => {
      state.filteredChips = action.payload;
    },
    clearFilteredChips: (state) => {
      state.filteredChips = [];
    },
    setFilteredUserChips: (state, action) => {
      state.filteredUserChips = action.payload;
    } ,
    clearFilteredUserChips: (state) => {
      state.filteredUserChips = [];
    },
    resetUserState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postUserFilterApi.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.userDetails = [];
    });

    builder.addCase(postUserFilterApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload.records;
      state.count = action.payload.count;
    });

    builder.addCase(postUserFilterApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

const { reducer } = userSlice;
export const { setInitUserDetail, setFilteredChips, clearFilteredChips, resetUserState, setFilteredUserChips, clearFilteredUserChips } = userSlice.actions;
export default reducer;
