import React from "react";
import { formatCreditBalance } from "../../../utils/sharedFunctions";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";

const CreditStatus = ({userDetails, totalTopUpAmount, setDateValue}) => {

  const formattedCreditBalance = formatCreditBalance(parseFloat(userDetails?.credit_balance ?? 0)) 
  const formattedTopUpCount = formatCreditBalance(parseFloat(totalTopUpAmount?.total_topup_count ?? 0)) 
  const formattedTopUpAmount = formatCreditBalance(parseFloat(totalTopUpAmount?.total_topup_amount ?? 0))
  const RoleId = parseInt(localStorageStore.getRole());

  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title mb-0 pt-1">Status</h5>
          <div className="card-title-elements ms-auto">
            <select className="form-select form-select-sm w-auto" onChange={(e) => setDateValue(e.target.value)}>
              <option value="3">Till today</option>
              <option value="1">Today</option>
              <option value="4">Week</option>
              <option value="2">Month</option>
            </select>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="row gy-0 align-items-center">
            <div className={RoleId === userRoleConstants.ADMIN_ID ? "col-md-3 col-6" : "col-md-4 col-6"}>
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-primary me-3 p-2">
                  <i className="ti ti-wallet ti-sm" />
                </div>
                <div className="card-info">
                  <h5 className="mb-0">
                    {formattedTopUpAmount} <small>USD</small> /{" "}
                    <small className="text-muted">Count: {formattedTopUpCount}</small>
                  </h5>
                  <small>No. of Top-Ups</small>
                </div>
              </div>
            </div>
            <div className={RoleId === userRoleConstants.ADMIN_ID ? "col-md-3 col-6" : "col-md-4 col-6"}>
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-info me-3 p-2">
                  <i className="ti ti-percentage ti-sm" />
                </div>
                <div className="card-info">
                  <h5 className="mb-0">
                    {userDetails?.commission ?? 0} <small>(%)</small>
                  </h5>
                  <small>Commission‌</small>
                </div>
              </div>
            </div>
            {RoleId === userRoleConstants.ADMIN_ID && <div className="col-md-3 col-6">
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-warning me-3 p-2">
                  <i className="ti ti-building-store ti-sm"></i>
                </div>            
                <div className="card-info">
                  <h5 className="mb-0">{totalTopUpAmount?.total_retailers ?? 0}</h5>
                  <small>No. of Retailer</small>
                </div>
              </div>
            </div> }
            <div className={RoleId === userRoleConstants.ADMIN_ID ? "col-md-3 col-6" : "col-md-4 col-6"}>
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-danger me-3 p-2">
                  <i className="ti ti-cash ti-lg" />
                </div>
                <div className="card-info">
                  <h3 className="mb-0 text-primary">
                    {formattedCreditBalance} <small>USD</small>
                  </h3>
                  <p className="mb-0 fw-medium">Credit Balance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default CreditStatus;