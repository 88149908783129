import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { formatCreditBalance } from "../../utils/sharedFunctions";
import { postUserFilterApi } from "../../redux/features/userSlice";
import { Link } from "react-router-dom";

const RetailerTable = ({ acc_no }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let page = 1;
  let size = 10;
  const DistributorId = localStorage.getItem("distributorOrRetailerId");

  useEffect(() => {
    setLoading(true); // Set loading state to true when API call is initiated
    if (DistributorId) {
      dispatch(postUserFilterApi({ filteredData: { user_id: DistributorId }, page, size }))
        .then(() => setLoading(false)) // Set loading state to false when API call is successful
        .catch(() => setLoading(false)); // Set loading state to false when API call fails
    }
    // eslint-disable-next-line
  }, [dispatch, DistributorId]);

  const userDetails = useSelector((state) => state.user.userDetails);

  const getSuffix = (day) => {
    const lastDigit = day?.slice(-1); // Get the last digit of the day

    // Check if the last digit is 1, 2, or 3 and return the corresponding suffix
    if (lastDigit === "1") {
      return "st";
    } else if (lastDigit === "2") {
      return "nd";
    } else if (lastDigit === "3") {
      return "rd";
    } else {
      return "th";
    }
  };

  return (
    <div className="col-md-9">
      <div className="card">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Retailers</h5>
          <div>
            <Link to={`/distributor/${acc_no}/retailers`} style={{ color: "#0282C3" }}>
              View All <i className="ti ti-chevron-right" />
            </Link>
          </div>
        </div>
        <div className="card-datatable table-responsive">
          {loading ? ( // Show loading indicator if data is being fetched
            <div className="px-3 py-3"><h5>Loading.....</h5></div>
          ) : (
            <table className="table" id="orders">
              <thead>
                <tr className="align-baseline">
                  <th>A/C No.</th>
                  <th width="15%">Location</th>
                  <th>Onboarding Date</th>
                  <th>Credit Day</th>
                  <th>Type</th>  
                  <th>
                    Commission <small>(%)</small>
                  </th>
                  <th width="10%">Top-Ups</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userDetails?.length !== 0 ?
                  (userDetails?.map((userDetail, index) => (
                    <tr key={index}>
                      <td> {userDetail?.account_no}</td>
                      <td>
                        {" "}
                        {userDetail?.state_name}, {userDetail?.country_code}{" "}
                      </td>
                      <td>{moment(userDetail?.createdAt).format("DD-MMM-YYYY")}</td>
                      <td>{parseInt(userDetail?.credit_day) !== 0 ? (<>{userDetail?.credit_day}<sup>{getSuffix(userDetail?.credit_day)}</sup></>) : ( 'N/A' )}</td>
                      <td className={userDetail?.billing_type_name === "Credit" ? "text-primary" : "text-info"}>{userDetail?.billing_type_name}</td>     
                      <td>{userDetail?.commission}</td>
                      <td>
                        <p className="mb-0">{formatCreditBalance(userDetail?.total_topups_amount ?? 0)} <small>USD</small> </p>
                        <small className="text-nuted">Count: {userDetail?.total_topups}</small>
                      </td>
                      <td><span className={userDetail?.is_active === 1 ? "badge px-2 bg-label-success" : "badge px-2 bg-label-danger"}>{userDetail?.is_active === 1 ? 'Active' : 'Inactive'}</span></td>
                    </tr>
                  ))) : (<tr className="pt-3 ps-3"><td colSpan="4">No record found</td></tr>)
                }
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default RetailerTable;
