import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ManageProfileHeader from "../../Shared/ManageProfile/ManageProfileHeader";
import ManageProfileDetails from "../../Shared/ManageProfile/ManageProfileDetails";
import localStorageStore from "../../../utils/localStorageStore";
import { userRoleConstants } from "../../../utils/constants";
import { getUserProfileByAccountNo } from "../../../services/userService";
import { errorToast } from "../../Toasts/Toasts";

const RetailerProfile = () => {
  const { acc_no } = useParams();
  const [reRender, setReRender] = useState(false)
  const RoleId = parseInt(localStorageStore.getRole());
  const [userDetails, setUserDetails]  = useState("");
  const [dateValue,setDateValue] = useState('3');

  useEffect(() => {

    fetchProfileDetails();

    // eslint-disable-next-line
  }, [reRender,dateValue]);

  const fetchProfileDetails = async() => {
    try {
      let response = await getUserProfileByAccountNo(acc_no, dateValue);
      setUserDetails(response?.data);
    } catch(error) {
      errorToast(error?.response?.data?.message);
    }
  }
   
  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          {RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID ? <h4>Retailer's Profile</h4> : <h4>Distributor's Profile</h4>}
        </div>
        <ManageProfileHeader userDetails={userDetails} setDateValue={setDateValue} />
        <ManageProfileDetails userDetails={userDetails} acc_no={acc_no} setReRender={setReRender} reRender={reRender}/>
      </div>
    </>
  );
};

export default RetailerProfile;
