import React from "react";

const StatusCard = ({ text, icon, text1, text2, text3, value1, value2, value3, currencyFormate, topUpCount }) => {
  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title mb-0">{text}</h5>
        </div>
        <div className="card-body pt-2">
          <div className="row gy-3">
            <div className="col-md-4 col-6">
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-primary me-3 p-2">
                  <i className="ti ti-wallet ti-sm" />
                </div>
                <div className="card-info">
                  <h5 className="mb-0">
                    {value1} <small>USD</small><small> {topUpCount} </small>  
                  </h5>
                  <small>{text1}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-info me-3 p-2">
                  <i className="ti ti-cash ti-sm" />
                </div>
                <div className="card-info">
                  <h5 className="mb-0">
                    {value2} <small>USD</small>
                  </h5>
                  <small>{text2}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="d-flex align-items-center">
                <div className="badge rounded-pill bg-label-warning me-3 p-2">
                  <i className={icon} />
                </div>
                <div className="card-info">
                  <h5 className="mb-0">
                    {value3} <small>{currencyFormate}</small>
                  </h5>
                  <small>{text3}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusCard;
