import React from "react";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2">
              <div className="card card-topup bg-outline-primary mt-4">
                <div className="d-flex col-12 col-lg-12 align-items-center py-3">
                  <div className="w-px-400 mx-auto">
                    <div className="card-body login-details">
                      <h3 className="mb-5 text-center">Register to continue</h3>
                      <form id="formAuthentication" className="mb-3" action="index.html" method="POST">
                        <Link  className="btn btn-primary text-white d-grid rounded-pill mb-4 login-phone" to="/customer/register/request-otp-phone-number">
                          Register With Mobile Number
                        </Link>
                        <Link  className="btn btn-primary text-white d-grid rounded-pill login-email" to="/customer/register/request-otp">
                          Register With Email
                        </Link>
                        <p className="mt-4 mb-0 text-center">
                          Already have an account? <Link to="/customer/login">Login here</Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                {/* /Register */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
