import React, { useEffect, useState } from "react";
import LogTransactionFilter from "./LogTransactionFilter";
import LogTransactionGrid from "./LogTransactionGrid";
import { getMasterData } from "../../../services/userService";
const LogTransaction = () => {
  const [masterData, setMasterData] = useState("");
  const [clearFilterValues, setClearFilterValues] = useState("");
  const [currentSize, setCurrentSize] = useState(10);
  let id = "";
  useEffect(() => {
    fetchMasterData();

    // eslint-disable-next-line
  }, []);

  const fetchMasterData = async () => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h4 className="mb-1 pt-1"> Reports</h4>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="nav-align-top">
            <ul className="nav nav-pills mb-3 border-bottom pb-3" role="tablist">
              <li className="nav-item" role="presentation">
                <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-top-shaad" aria-controls="navs-pills-top-shaad" aria-selected="true">
                  <i className="ti ti-arrows-left-right me-1" /> Transaction
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-pills-top-retailer" aria-controls="navs-pills-top-retailer" aria-selected="false" tabIndex={-1}>
                  <i className="ti ti-settings me-1" /> API
                </button>
              </li> */}
            </ul>
                                  
            <div className="tab-content p-0 credit-tabs">
              <div className="tab-pane fade active show" id="navs-pills-top-shaad" role="tabpanel">
                <div className="d-flex justify-content-end align-items-center mb-4">
                  <LogTransactionFilter masterData={masterData} clearFilterValues={clearFilterValues} currentSize={currentSize}/>
                  {/* <button type="submit" className="btn btn-sm btn-secondary rounded-pill btn-smwaves-effect waves-light" data-bs-toggle="modal" data-bs-target="#addRetailer">
                    <i className="ti ti-download me-1"></i>
                    Reports
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LogTransactionGrid setClearFilterValues={setClearFilterValues} currentSize={currentSize} setCurrentSize={setCurrentSize} />
    </>
  );
};

export default LogTransaction;
