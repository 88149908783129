import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { updateContactDetails, updateCustomerProfile } from "../../../services/customerService";
import { getCustomerProfileApi } from "../../../redux/features/customerProfileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../../redux/features/authSlice";

const EditModal = ({ showEditProfileModal, setShowEditProfileModal, selectedContact, masterData, handleUpdateContact }) => {
  
  const defaultCarrierLabel = selectedContact?.carrier_name !== null ? selectedContact?.carrier_name : "";
  const defaultCarrierValue = selectedContact?.carrier_id !== null ? selectedContact?.carrier_id : "";
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm({ defaultValues: { customer_name: selectedContact?.name ?? "", mobile_number: selectedContact?.phone_code && selectedContact?.phone_number ? `${selectedContact?.phone_code} ${selectedContact?.phone_number}` : "", email: selectedContact?.email ?? "", carrier: { label: defaultCarrierLabel, value: defaultCarrierValue }, image: selectedContact?.image ?? "" } });

  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"];
  const maxSize = 1 * 1024 * 1024; // 1 MB;

  const dispatch = useDispatch();

  const handleClose = () => setShowEditProfileModal(false);

  const [avatar, setAvatar] = useState(selectedContact?.image);

  const [emailChanged, setEmailChanged] = useState(false);
  
  const carrierOptions = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check if the file type is allowed
      if (ALLOWED_FILE_TYPES.includes(file.type)) {
        if (file.size <= maxSize) {
          const reader = new FileReader();
          reader.onloadend = () => {
          const base64String = reader.result;
            setAvatar(base64String); // Store the base64 string
          };
          reader.readAsDataURL(file);
        } else {
          // Show error message for oversized file
          errorToast("Maximum file size allowed is 1 MB.");
        }
      } else {
        // Show error message for invalid file type
        errorToast("Only JPEG, PNG, and JPG images are allowed.");
      }
    }
  };

  const onSubmit = async (data) => {
    const button = document.getElementById("saveButton");
    button.disabled = true;

    let ContactInfo = {
      name: data?.customer_name,
      carrier_id: data?.carrier?.value, // Corrected carrier_id assignment
      email: data?.email,
      image: avatar,
      phone_code: selectedContact?.phone_code,
      phone_number: selectedContact?.phone_number,
    };
    
    try {
      if (selectedContact?.contact_record_type === "customer") {
        const response = await updateCustomerProfile(ContactInfo);
        if (response?.success) {
          successToast(response?.message);
          handleUpdateContact(response);
          handleClose();
          button.disabled = true; 
          if (selectedContact?.email !== data?.email) {
            handleLogout();
          } else {
            dispatch(getCustomerProfileApi());
          }
          
        } else {
          errorToast(response?.message || "Unknown error occurred.");
        }
      } else {
        const response = await updateContactDetails(selectedContact?.id, ContactInfo);
        if (response?.success) {
          successToast(response?.message);
          handleUpdateContact(response);
          handleClose();
          button.disabled = true;
        } else {
          errorToast(response?.message || "Unknown error occurred.");
        }
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while updating contact details.");
      button.disabled = false;
    }
  };

  const handleEmailChange = (e) => {
    const newEmailValue = e.target.value;

    if (selectedContact?.contact_record_type === "customer" && newEmailValue !== selectedContact?.email) {
      setEmailChanged(true);
      setValue("email", newEmailValue);
    } else{
      setEmailChanged(false);
      setValue("email", newEmailValue);
    }

  };
 
  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.removeItem("token");
    localStorage.removeItem("roleId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedContactData");
    localStorage.removeItem("userId");
    localStorage.removeItem("ContactId");
    localStorage.removeItem("contactDetailsAfterLogin");
    localStorage.removeItem("profileData");
    navigate("/customer/login");
  };

  return (
    <Modal show={showEditProfileModal} size="sm" centered>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <Modal.Body>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose} />
            <div className="text-left mb-4">
              <h3 className="mb-2">Edit Details</h3>
            </div>
            <div className="add-number">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="profile-change gap-4">
                      <div
                        className="custom-img-container rounded"
                        style={{
                          backgroundImage: `url(${avatar})`,
                        }}
                      />
                      <div className="button-wrapper mt-3">
                        <label htmlFor="upload" className="btn btn-outline-primary rounded-pill btn-sm me-2 mb-3 waves-effect waves-light" tabIndex={0}>
                          <span className="d-none d-sm-block">Upload new photo</span>
                          <i className="ti ti-upload d-block d-sm-none" />
                          <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg, image/jpg" onChange={handleImageChange} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div>
                      <label htmlFor="" className="form-label">
                        Name
                      </label>
                      <input type="text" placeholder="" className="form-control" name="customer_name" minLength={1} maxLength={256} {...register("customer_name", { required: "required" })} />
                      <small className="text-danger">{errors?.customer_name && errors.customer_name.message}</small>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div>
                      <label htmlFor="" className="form-label">
                        Mobile Number
                      </label>
                      <input type="text" placeholder="" className="form-control" name="mobile_number" minLength={1} maxLength={256} {...register("mobile_number", { required: "required" })} disabled />
                      <small className="text-danger">{errors?.mobile_number && errors.mobile_number.message}</small>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <div>
                      <label htmlFor="" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className={`form-control  ${errors.email ? "is-invalid" : ""}`}
                        id="email"
                        {...register("email", {
                          required: "required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        onChange={handleEmailChange}
                        placeholder="Enter your email"
                      />
                      {errors.email && <small className="text-danger">{errors.email.message}</small>}{" "}
                    </div>
                    {emailChanged && (
                      <>
                        <div className="alert alert-primary d-flex mt-2 email-change" role="alert">
                        <i className="ti ti-info-circle text-primary me-1"></i>
                          <div>Updating the email will log you out, and you'll need to log in with the new email address.</div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-12 mt-3">
                    <label className="form-label">Carrier</label>
                    <Controller
                      name="carrier"
                      control={control}
                      rules={{ required: "required" }}
                      render={({ field }) => (
                        <>
                          <Select {...field} options={carrierOptions()} isSearchable placeholder="Select Carrier..." />
                          {errors.carrier && <small className="text-danger">{errors.carrier.message}</small>}
                        </>
                      )}
                    />
                  </div>

                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-xl-1" id="saveButton">
                      Update
                    </button>
                    <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;