import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { topUpRequest, topUpSummary } from "../../../services/transactionService";
import { errorToast } from "../../Toasts/Toasts";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { Link } from "react-router-dom";
import CustomAlert from "../../Shared/CustomAlert";

const TopUpThroughRetailer = ({ masterData, setShowProductSelect, setProductAmount, productAmount, finalCommission, productId, handleReRenderData, setShowImage}) => {
  
  const [topUpAmountSummary, setTopUpAmountSummary] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [showCustomeAlertModal, setShowCustomeAlertModal] = useState(false);
  const keywordRef = useRef("");
  const [alertMessage,setAlertMessage] = useState('');
  const [showClassName, setShowClassName] = useState('');
  const [iconClassName, setIconClassName] = useState('');
  
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      mobileNumber: "",
      carrier: "",
      topUpAmount: productAmount? productAmount : "",
      requestersName: "",
    },
  });

  useEffect(() => {
    // Update the topUpAmount input field whenever productAmount changes
    setValue("topUpAmount", productAmount);
    const fetchData = async () => {
      try {
        const response = await topUpSummary({ amount: parseFloat(productAmount), final_commission: finalCommission, product_id : productId});
        setTopUpAmountSummary(response);
        setLoader(false);
      } catch(error) {
        setTopUpAmountSummary(null);
        errorToast(error?.response?.data?.message || error?.response?.data?.errors[0]?.message || "Something went wrong");
      }
    }
    if(productAmount) {
      fetchData();
    }
      // Check if phoneNumber starts with "78" or "73" to select Etisalat
    if (phoneNumber.startsWith('78') || phoneNumber.startsWith('73')) {
      const etisalatCarrier = masterData?.carriers?.find(carrier => carrier?.name === 'Etisalat');
      if (etisalatCarrier) {
        setValue('carrier', { label: etisalatCarrier?.name, value: etisalatCarrier?.id });
      }
    } else if (phoneNumber.startsWith('79') || phoneNumber.startsWith('72')) {
      const roshanCarrier = masterData?.carriers?.find(carrier => carrier?.name === 'Roshan');
      if (roshanCarrier) {
        setValue('carrier', { label: roshanCarrier?.name, value: roshanCarrier?.id });
      }
    }
    else{
      if(!getValues('carrier')) {
        setValue('carrier', '');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productAmount, phoneNumber]);

  const carrierOptions = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const onSubmit = async (formData) => {
    try {
      let obj = {
        phone_code: phoneCode,
        mobile_number: phoneNumber,
        requested_by: formData?.requestersName,
        aggregator_id: 1,
        carrier_id: formData?.carrier?.value,
        original_currency_type: topUpAmountSummary?.sourceCurrency,
        converted_currency_type: topUpAmountSummary?.convertedCurrency,
        commission: topUpAmountSummary?.commissionAmountOnConvertedCurrency,
        service_fee: topUpAmountSummary?.serviceFee,
        vat: topUpAmountSummary?.vat,
        total_amount_source_currency: topUpAmountSummary?.totalSourceAmount,
        total_amount_converted_currency: topUpAmountSummary?.totalConvertedAmount, 
        topup_amount_source_currency: topUpAmountSummary?.sourceAmount,
        topup_amount_converted_currency: topUpAmountSummary?.convertedAmount,
            
      };
      const response = await topUpRequest(obj);
      if (response?.success) {
        if (response?.message?.toLowerCase()?.includes("success") ) {
          setShowCustomeAlertModal(true);
          setAlertMessage(response?.message);
          setShowClassName('text-success check info');
          setIconClassName('ti ti-check fs-1');
        }  else if (response?.message?.toLowerCase()?.includes("process")) {
          setShowCustomeAlertModal(true);
          setAlertMessage(response?.message);
          setShowClassName('text-warning check warning');
          setIconClassName('ti ti-loader fs-1');
        } else {
          setShowCustomeAlertModal(true);
          setAlertMessage(response?.message);
          setShowClassName('text-danger check danger');
          setIconClassName('ti ti-x fs-1');
        }
        handleReset();
        handleReRenderData(response);
        setShowProductSelect(false);
        setShowImage(true);

      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  let timeoutId;
  const handleTopUpAmountChange = async (event) => {
    setShowProductSelect(false);
    setShowImage(true);
    const keyword = event?.target?.value?.trim();
    setLoader(true);

    if (keyword !== "" && keyword.length >= 2 ) {
      keywordRef.current = keyword; // Update the ref with the latest keyword
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        // Check if the current keyword is still the latest before making the API call
        if (keywordRef.current === keyword) {
          try {
            const response = await topUpSummary({ amount: parseFloat(keyword) });
            setTopUpAmountSummary(response);
            setLoader(false);
          } catch(error) {
            setTopUpAmountSummary(null);
            setLoader(false);
            errorToast(error?.response?.data?.message || error?.response?.data?.errors[0]?.message || "Something went wrong");
          }

        }
      }, 600);
    } else {
      setTopUpAmountSummary(null);
      setLoader(false);
    }
  };

  const handleReset = () => {
    reset({
      mobileNumber: "",
      requestersName: "",
      topUpAmount: "",
      carrier: "",
    });
    setTopUpAmountSummary(null);
    setShowProductSelect(false);
    setShowImage(true);
  };

  const handleChange = (value) => {
    const stringValue = String(value);
    const parsedPhoneNumber = parsePhoneNumber(stringValue);
    if (parsedPhoneNumber) {
      setPhoneCode(`+${parsedPhoneNumber?.countryCallingCode}`);
      setPhoneNumber(parsedPhoneNumber?.nationalNumber);
      setValue("mobileNumber", stringValue);
    }
    else {
      setPhoneCode("");
      setPhoneNumber("");
      setValue("mobileNumber", ""); // Ensure the field value is updated
    }
    if (errors.mobileNumber) {
      setValue("mobileNumber", value, { shouldValidate: true });
    }
  };
 
  const handleProductSelection = () => {
    setShowProductSelect(true);
    setShowImage(false);
  }

  return (
    <>
      <div className="col-md-5">
        <div className="card mt-3 mt-lg-0">
          <div className="card-header border-bottom mb-2">
            <div className="card-title mb-0">
              <h5 className="mb-0">Top Up</h5>
            </div>
          </div>
          <div className="card-body p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <label htmlFor="phone" className="required form-label">
                    Mobile Number
                  </label>
                  <Controller
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: "Required",
                    }}
                    render={({ field }) => (
                      <>
                        <PhoneInput
                          className="form-control"
                          international
                          countryCallingCodeEditable={false}
                          // countrySelectProps={{ unicodeFlags: true }}
                          defaultCountry="AF"
                          value={field.value}
                          countries={['AF']}
                          onChange={(value) => {
                            handleChange(value);
                          }}
                        />
                        {errors?.mobileNumber && <small className="text-danger">{errors.mobileNumber.message}</small>}
                      </>
                    )}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label required">Carrier</label>
                  <Controller
                    name="carrier"
                    control={control}
                    rules={{ required: "required" }}
                    render={({ field }) => (
                      <>
                        <Select {...field} options={carrierOptions()} isSearchable placeholder="Select Carrier..." />
                        {errors.carrier && <span className="text-danger">{errors.carrier.message}</span>}
                      </>
                    )}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label required">
                    Top-Up Amount <small>(Retailer selected)</small>
                  </label>
                  <Controller
                    name="topUpAmount"
                    control={control}
                    rules={{
                      required: "Required",
                      min: { value: 40, message: "Amount must be greater or equal to 40" },
                      max: { value: 5000, message: "Amount must be less than or equal to 5000" },
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          type="text"
                          className="form-control mb-3"
                          {...field}
                          placeholder="90"
                          onChange={(e) => {
                            field.onChange(e);
                            handleTopUpAmountChange(e);
                          }}
                          min={0}
                        />
                        {errors.topUpAmount && <span className="text-danger">{errors.topUpAmount.message}</span>}
                      </>
                    )}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label className="form-label required">Requester's Name</label>
                  <Controller
                    name="requestersName"
                    control={control}
                    rules={{ required: "required" }}
                    render={({ field }) => (
                      <>
                        <input type="text" className="form-control mb-3" {...field} placeholder="Akira" />
                        {errors.requestersName && <span className="text-danger">{errors.requestersName.message}</span>}
                      </>
                    )}
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <Link to="#" className="select-plan" onClick={handleProductSelection}>Select From Products <i className="ti ti-arrow-narrow-right ms-1"></i></Link>
                </div>
              </div>
              <div className="border-bottom border-bottom-dashed mt-0 mb-4"></div>
              {topUpAmountSummary && (
                <>
                  <div className="row ">
                    <div className="col-md-12 mb-md-0 mb-3">
                      <div className="d-flex justify-content-between mb-0">
                        <h5 className="mb-0">Summary</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      {/* Summary Section */}
                      <div className="d-flex w-100 flex-wrap justify-content-between py-2 mt-3">
                        <div className="me-2">
                          <h6 className="mb-0">Amount</h6>
                        </div>
                        <div className="user-progress text-right align-items-center gap-1">
                          <p className="mb-0 fw-medium h5">
                            <span className="text-primary me-2">
                              ({topUpAmountSummary?.sourceAmount} <small>{topUpAmountSummary?.sourceCurrency}</small>)
                            </span>
                            <span className="h5">
                              {topUpAmountSummary?.convertedAmount} <small>{topUpAmountSummary?.convertedCurrency}</small>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                        <div className="me-2">
                          <h6 className="mb-0">Service Fee</h6>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-1">
                          <p className="mb-0 fw-medium h6">
                            {(topUpAmountSummary?.serviceFee + (topUpAmountSummary.commissionAmountOnConvertedCurrency ? topUpAmountSummary?.commissionAmountOnConvertedCurrency : 0)).toFixed(4)} <small>{topUpAmountSummary?.convertedCurrency}</small>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                        <div className="me-2">
                          <h6 className="mb-0">Taxes</h6>
                        </div>
                        <div className="user-progress d-flex align-items-center gap-1">
                          <p className="mb-0 fw-medium h6">
                            {topUpAmountSummary?.vat} <small>{topUpAmountSummary?.convertedCurrency}</small>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between border-top mt-2 pt-2">
                        <h5 className="mb-1">Total</h5>
                        <div>
                          <p className=" h4 mb-1">
                            <span className="text-primary me-2 fs-5">
                              ({topUpAmountSummary?.totalSourceAmount} <small>{topUpAmountSummary?.sourceCurrency}</small>)
                            </span>
                            {topUpAmountSummary?.totalConvertedAmount} <small>{topUpAmountSummary?.convertedCurrency}</small>
                          </p>
                        </div>
                      </div>
                      {topUpAmountSummary?.commissionAmountOnOriginalCurrency ? (<div className="d-flex justify-content-between mt-0 pt-0 border-top pt-2">
                        <h6>Total Commission Earned</h6>
                        <div>
                          <p className="h5">
                            <span className="text-blue me-2 fs-5">
                              ({topUpAmountSummary?.commissionAmountOnOriginalCurrency} <small>{topUpAmountSummary?.sourceCurrency}</small>)
                            </span>
                            {topUpAmountSummary?.commissionAmountOnConvertedCurrency} <small>{topUpAmountSummary?.convertedCurrency}</small>
                          </p>
                        </div>
                      </div>) : null }
                    </div>
                    <span className="text-primary"> * Government taxes may apply on final top-up amount</span>
                  </div>
                </>
              )}
              {loader && (<div className="text-center"><span className="spinner-border h-15px w-15px align-middle text-danger" /></div>)}
              
              <div className="col-12 text-center mt-4">
                <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light" data-bs-target="#Success">
                  Top-Up
                </button>
                <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleReset}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomAlert showCustomeAlertModal={showCustomeAlertModal} setShowCustomeAlertModal={setShowCustomeAlertModal} text={alertMessage} showClassName={showClassName} iconClassName={iconClassName}/>
    </>
  );
};

export default TopUpThroughRetailer;
