import React, { useEffect, useState } from "react";
import { errorToast } from "../../Toasts/Toasts";

import { customerProfile } from "../../../services/AdminCustomer";
import { formatCreditBalance } from "../../../utils/sharedFunctions";

const CustomerHeader = ({ id }) => {
  const [customerDetails, setCustomerDetails] = useState("");

  useEffect(() => {
    fetchCustomerProfile();

    // eslint-disable-next-line
  }, []);

  const fetchCustomerProfile = async () => {
    try {
      const response = await customerProfile(id);
      if (response?.success) {
        setCustomerDetails(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="col-lg-4 col-sm-6 mb-4">
        <div className="card card-border-shadow-primary h-100">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center pb-1">
                <div className="avatar me-2">
                  <img src="../assets/img/avatars/2.png" alt="Avatar" className="rounded-circle me-3" />
                </div>
                <h4 className="ms-1 mb-0">{customerDetails?.customerInfo?.name}</h4>
              </div>
            </div>
            <p className="mb-1 mt-2">
              Carrier: <span className="fw-medium">{customerDetails?.customerInfo?.carrier_name}</span>
            </p>
            <p className="mb-0">
              <small className="text-muted">
                <i className="ti ti-phone ti-xs" />
              </small>
              <span className="fw-medium me-1">
                {customerDetails?.customerInfo?.phone_code} {customerDetails?.customerInfo?.phone_number}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 mb-4 col-md-12">
        <div className="card h-100">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title mb-0">Top-Up</h5>
          </div>
          <div className="card-body pt-2">
            <div className="row gy-3">
              <div className="col-md-12 col-6">
                <div className="d-flex align-items-center">
                  <div className="badge rounded-pill bg-label-warning me-3 p-2">
                    <i className="ti ti-wallet ti-sm" />
                  </div>
                  <div className="card-info">
                    <h5 className="mb-0">
                    {formatCreditBalance(customerDetails?.count?.total_topup_amount ?? 0)} <small>USD</small> / <small>Count: {formatCreditBalance(customerDetails?.count?.total_count_topup_approved_transaction ?? 0)}</small>
                    </h5>
                    <small>Total Top-Ups</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerHeader;
