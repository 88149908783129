import React from "react";
import { Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getTransactionDetailApi, setFilteredChips, clearFilteredChips } from "../../../redux/features/transactionCreditSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { errorToast, successToast } from "../../Toasts/Toasts";

const BillingFilterModal = ({ billingFilterShow, setBillingFilterShow, selectedRoleTabId, paymentTypeTabId, roleId, userRoleConstants, creditedToOrBy, currentSize, transactionId, setTransactionId, setAmount, amount, requestDate, setRequestDate, submitRequestDate, setSubmitRequestDate, creditDate, setCreditDate, submitCreditDate, setSubmitCreditDate, dueDate, setDueDate, submitDueDate, setSubmitDueDate, paymentDate, setPaymentDate, submitPaymentDate, setSubmitPaymentDate, credit_status_type_id, setStatus, retailerName, setRetailerName, accountNumber, setAccountNumber, distributorName, setDistributorName }) => {
  const page = 1;

  const handleFilterDisplay = () => {
    setBillingFilterShow(!billingFilterShow);
  };

  const dispatch = useDispatch();

  const handleTransactionId = (e) => {
    setTransactionId(e?.target?.value);
  };

  const handleAmount = (e) => {
    setAmount(e?.target?.value);
  };

  const handleEventRequestDate = (event, picker) => {
    picker.element.val(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setRequestDate(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setSubmitRequestDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleEventPaymentDate = (event, picker) => {
    picker.element.val(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setPaymentDate(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setSubmitPaymentDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleEventDueDate = (event, picker) => {
    picker.element.val(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setDueDate(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setSubmitDueDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleEventCreditDate = (event, picker) => {
    picker.element.val(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setCreditDate(picker.startDate.format("DD-MM-YYYY") + " - " + picker.endDate.format("DD-MM-YYYY"));
    setSubmitCreditDate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelRequestDate = (event, picker) => {
    picker.element.val("");
    setRequestDate("");
    setSubmitRequestDate("");
  };

  const handleCancelCreditDate = (event, picker) => {
    picker.element.val("");
    setCreditDate("");
    setSubmitCreditDate("");
  };

  const handleCancelDueDate = (event, picker) => {
    picker.element.val("");
    setDueDate("");
    setSubmitDueDate("");
  };

  const handleCancelPaymentDate = (event, picker) => {
    picker.element.val("");
    setPaymentDate("");
    setSubmitPaymentDate("");
  };

  const handleRetailerName = (e) => {
    setRetailerName(e?.target?.value);
  };

  const handleAccountNumber = (e) => {
    setAccountNumber(e?.target?.value);
  };

  const handleDistributorName = (e) => {
    setDistributorName(e?.target?.value);
  };

  const statusOptions = [
    { label: "Requested", value: "3" },
    { label: "Approved", value: "1" },
    { label: "Declined", value: "2" },
    ...(((roleId === 2 && paymentTypeTabId === 1) || (roleId === 3 && selectedRoleTabId === 0))
      ? [
          { label: "Paid", value: "5" },
          { label: "Unpaid", value: "6" },
        ]
      : []
    )
  ];

  const data = {
    transaction_id: transactionId,
    amount: amount,
    request_date: submitRequestDate,
    credit_date: submitCreditDate,
    credit_status_type_id: credit_status_type_id?.value ?? "",
    type: roleId === userRoleConstants.ADMIN_ID ? "credited_by" : roleId === userRoleConstants.RETAILER_ID ? "credited_to" : creditedToOrBy,
    billing_type_id: roleId === userRoleConstants.ADMIN_ID ? paymentTypeTabId : roleId === userRoleConstants.RETAILER_ID ? (selectedRoleTabId === 0 ? 1 : 2) : paymentTypeTabId,
  };

  const chipsData = {
    transaction_id: transactionId,
    amount: amount,
    request_date: submitRequestDate,
    credit_date: submitCreditDate,
    credit_status_type_id: credit_status_type_id ?? "",
  };

  if (((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 1)) {
    data.due_date = submitDueDate;
    data.payment_date = submitPaymentDate;
    chipsData.due_date = submitDueDate;
    chipsData.payment_date = submitPaymentDate;
  }

  if ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) {
    data.account_no = accountNumber;
    data.name = retailerName;
    chipsData.account_no = accountNumber;
    chipsData.name = retailerName;
  }

  if (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2) {
    data.account_no = accountNumber;
    data.name = distributorName;
    chipsData.account_no = accountNumber;
    chipsData.name = distributorName;
  }

  if (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 0) {
    delete data.credit_status_type_id;
    delete chipsData.credit_status_type_id;
  }

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(data);
  const filteredChipsData = filterEmptyFields(chipsData);

  const handleCloseModal = () => {
    setTransactionId("");
    setAmount("");
    setRequestDate("");
    setSubmitRequestDate("");
    setCreditDate("");
    setSubmitCreditDate("");
    setDueDate("");
    setSubmitDueDate("");
    setPaymentDate("");
    setSubmitPaymentDate("");
    setStatus("");
    setRetailerName("");
    setAccountNumber("");
    setDistributorName("");
    dispatch(clearFilteredChips());
    dispatch(getTransactionDetailApi({ filterData: { type: roleId === userRoleConstants.ADMIN_ID ? "credited_by" : roleId === userRoleConstants.RETAILER_ID ? "credited_to" : creditedToOrBy, billing_type_id: roleId === userRoleConstants.ADMIN_ID ? paymentTypeTabId : roleId === userRoleConstants.RETAILER_ID ? (selectedRoleTabId === 0 ? 1 : 2) : paymentTypeTabId }, page, size: currentSize }));
    setBillingFilterShow(!billingFilterShow);
  };

  const submitFilterData = async () => {
    if (Object.keys(filteredData).length > 2) {
      const response = await dispatch(getTransactionDetailApi({ filterData: filteredData, page, size: currentSize }));

      if (response?.meta?.requestStatus === "fulfilled") {
        successToast("Filter applied successfully");
      }

      dispatch(setFilteredChips(Object.entries(filteredChipsData)));
      setBillingFilterShow(!billingFilterShow);
    } else {
      errorToast("All fields are empty");
    }
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-sm waves-effect waves-light me-2" onClick={handleFilterDisplay}>
        <i className="ti ti-filter me-1" />
        Filter
      </button>
      {
        <Modal show={billingFilterShow} size="lg" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <div className="modal-body" style={{marginBottom: "1rem"}}>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseModal} aria-label="Close" />
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <label className="form-label">Transaction ID</label>
                    <input type="text" placeholder="" value={transactionId} onChange={handleTransactionId} className="form-control mb-3" />
                  </div>
                  {(((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2)) && (
                    <div className="col-lg-4">
                      <label className="form-label"> Account No. </label>
                      <input type="text" placeholder="" value={accountNumber} onChange={handleAccountNumber} className="form-control mb-3" />
                    </div>
                  )}
                  {roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && (
                    <div className="col-lg-4">
                      <label className="form-label"> Distributor Name </label>
                      <input type="text" placeholder="" value={distributorName} onChange={handleDistributorName} className="form-control mb-3" />
                    </div>
                  )}
                  {(roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID)&& selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2) && (
                    <div className="col-lg-4">
                      <label className="form-label"> Retailer Name </label>
                      <input type="text" placeholder="" value={retailerName} onChange={handleRetailerName} className="form-control mb-3" />
                    </div>
                  )}
                  <div className="col-lg-4">
                    <label className="form-label">
                      {" "}
                      Amount <small>(USD)</small>{" "}
                    </label>
                    <input type="text" placeholder="" value={amount} onChange={handleAmount} className="form-control mb-3" />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Request Date</label>
                    <DateRangePicker initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: "Clear", format: "DD-MM-YYYY" }, showDropdowns: true, drops: (selectedRoleTabId === 0 || roleId === 3) ? "down" : "up" }} onApply={handleEventRequestDate} onCancel={handleCancelRequestDate}>
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={requestDate} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-4 mt-3 mt-lg-0">
                    <label className="form-label">Credit Date</label>
                    <DateRangePicker initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: "Clear", format: "DD-MM-YYYY" }, showDropdowns: true, drops: "up" }} onApply={handleEventCreditDate} onCancel={handleCancelCreditDate}>
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={creditDate} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  {(((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 1)) && (
                    <div className="col-lg-4">
                      <label className="form-label">Due Date</label>
                      <DateRangePicker initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: "Clear", format: "DD-MM-YYYY" }, showDropdowns: true, drops: "up" }} onApply={handleEventDueDate} onCancel={handleCancelDueDate}>
                        <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={dueDate} autoComplete="off" />
                      </DateRangePicker>
                    </div>
                  )}
                  {(((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (selectedRoleTabId === 0 || selectedRoleTabId === 1) && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 1)) && (
                    <div className="col-lg-4 mt-3 mt-lg-0">
                      <label className="form-label">Payment Date</label>
                      <DateRangePicker initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: "Clear", format: "DD-MM-YYYY" }, showDropdowns: true, drops: "up" }} onApply={handleEventPaymentDate} onCancel={handleCancelPaymentDate}>
                        <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={paymentDate} autoComplete="off" />
                      </DateRangePicker>
                    </div>
                  )}
                  {!(roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 0) && (
                    <div className="col-lg-4 mt-3 mt-lg-0">
                      <label className="form-label">Status</label>
                      <Select options={statusOptions} isMulti={false} onChange={(selectedOption) => setStatus(selectedOption)} value={credit_status_type_id} />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-credit-shaadfilter" onClick={submitFilterData}>
                      Submit
                    </button>
                    <button type="submit" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleCloseModal}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default BillingFilterModal;
