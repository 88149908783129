import React, { useState } from "react";
import StatusCard from "../StatusCard/SmallStatusCard";
import { useDispatch } from "react-redux";
import { requestTransactionApi } from "../../../redux/features/requestTransactionSlice";
import { errorToast, successToast } from "../../Toasts/Toasts";
import { formatCreditBalance } from "../../../utils/sharedFunctions";
import moment from 'moment';
import { getTransactionDetailApi } from "../../../redux/features/transactionCreditSlice";

const BillingStatusCards = ({ selectedRoleTabId, paymentTypeTabId, roleId, userRoleConstants, count, transformedFilterData, page, currentSize }) => {
  const dispatch = useDispatch();

  const [creditAmount, setCreditAmount] = useState(0);

  const handleRequestAirtimeCredit = (e) => {
    setCreditAmount(e?.target?.value);
  };

  const handleSubmit = async () => {
    try {
      if(creditAmount > 0){
      const response = await dispatch(requestTransactionApi({ amount: creditAmount }));
      dispatch(getTransactionDetailApi({ filterData: transformedFilterData, page, size: currentSize + 10 }));
    
      if (response.meta.requestStatus === "fulfilled") {
        successToast(response.payload.message);
      } else {
        errorToast(response.payload.message);
      }
    } else {
      errorToast("Amount should not be null or less then 1");
    }
    } catch (error) {
      
      errorToast(error)
      // Optionally, display an error toast or perform other error handling actions
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-12 mb-4">{((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <StatusCard color="primary" icon="ti ti-wallet ti-sm" value={formatCreditBalance(parseFloat(count?.total_credit_limit ?? 0))} label="Total Credit Limit" currency_format="USD" /> : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 2) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 1) ? <StatusCard color="info" icon="ti ti-cash ti-sm" value={formatCreditBalance(parseFloat(count?.total_credit_balance ?? 0))} label="Total Live Balance" currency_format="USD" /> : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && paymentTypeTabId === 1) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 1) ? <StatusCard color="primary" icon="ti ti-cash ti-sm" value={formatCreditBalance(parseFloat(count?.total_approved_credits ?? 0))} label="Total Credited Balance" currency_format="USD" /> : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && paymentTypeTabId === 2) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 2) ? <StatusCard color="primary" icon="ti ti-message ti-sm" value={formatCreditBalance(count?.all_credit_transactions_count ?? 0)} label="Total Request" /> : roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 0 ? <StatusCard color="primary" icon="ti ti-cash ti-sm" value="0" label="Credited" /> : ""}</div>
        <div className="col-lg-3 col-12 mb-4">{((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <StatusCard color="info" icon="ti ti-cash ti-sm" value={formatCreditBalance(parseFloat(count?.total_credit_balance ?? 0))} label="Total Live Balance" currency_format="USD" /> : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 2) ? <StatusCard color="success" icon="ti ti-check ti-sm" value={formatCreditBalance(count?.total_approved_credits ?? 0)} label="Total Approved" currency_format="USD" /> : roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 0 ? <StatusCard color="info" icon="ti ti-chart-line ti-sm" value="0" label="Consumed" /> : roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 1 ? <StatusCard color="success" icon="ti ti-check ti-sm" value={formatCreditBalance(count?.all_credit_transactions_count ?? 0)} label="Total Count" /> : ""}</div>
        <div className="col-lg-3 col-12 mb-4">{((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? <StatusCard color="warning" icon="ti ti-calendar ti-sm" value={moment(count?.due_date).format("DD MMM YY")} label="Due Date" /> : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) ? <StatusCard color="warning" icon="ti ti-loader ti-sm" value={formatCreditBalance(parseFloat(count?.total_pending_credits ?? 0))} label="Total Pending" currency_format="USD" /> : roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 0 ? <StatusCard color="warning" icon="ti ti-check ti-sm" value="0" label="Available" /> : ""}</div>
        {((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 1 && paymentTypeTabId === 1) || (roleId === userRoleConstants.ADMIN_ID && selectedRoleTabId === 2 && paymentTypeTabId === 1) ? (
          <div className="col-lg-3 col-12 mb-4">
            <StatusCard color="info" icon="ti ti-calendar ti-sm" value={formatCreditBalance(parseFloat(count?.total_due_balance ?? 0))} label="Total Due" currency_format="USD" />{" "}
          </div>
        ) : (
          ""
        )}
        {((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && (paymentTypeTabId === 1 || paymentTypeTabId === 2)) || (roleId === userRoleConstants.RETAILER_ID && (selectedRoleTabId === 0 || selectedRoleTabId === 1)) ? (
          <div className="col-md-3 col-12 mb-4">
            <div className="card request-credit-card">
              <div className="p-4 py-3 pt-2">
                <div className="col-md-12">
                  <label className="form-label fs-6">
                    {((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 1) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 0) ? "Request Airtime Credit" : ((roleId === userRoleConstants.DISTRIBUTOR_ID || roleId === userRoleConstants.DISTRIBUTOR_USER_ID) && selectedRoleTabId === 0 && paymentTypeTabId === 2) || (roleId === userRoleConstants.RETAILER_ID && selectedRoleTabId === 1) ? "Request Airtime Purchase" : ""}
                    <span className="small">(USD)</span>
                  </label>
                  <div className="d-flex justify-content-between">
                    <input type="number" onChange={handleRequestAirtimeCredit} placeholder="Enter Amount" min={0} className="form-control form-control-sm" />
                    <button type="button" className="btn btn-sm btn-primary waves-effect waves-light ms-2" onClick={handleSubmit}>
                      Go
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ( "" )} 
      </div>
    </>
  );
};

export default BillingStatusCards;
